import Landing from './pre-pages/landing/landing.jsx'
import Main from './pages/main/main.jsx'
import Splash from './pre-pages/splash/splash.jsx'

import Ar from './pages/ar/ar.jsx'
import ArSceneList from './pages/ar-scenes/ar-scene-list.jsx'
import Discover from './pages/discover/discover.jsx'
import DiscoverViews from './pages/discover/discover-views.jsx'
import DiscoverViewDetail from './pages/discover/discover-view-detail.jsx'
import DiscoverContentDetail from './pages/discover/discover-content-detail.jsx'
import Profilo from './pages/profile/profilo.jsx'
import Credits from './pages/credits/credits.jsx'
import Tutorial from './pages/tutorial/tutorial.jsx'
import Notifications from './pages/notifications/notifications.jsx'
import Login from './pages/login/login.jsx'
import ContactUs from './pages/contact-us/contact-us.jsx'
import AssetRecognition from './pages/asset-recognition/asset-recognition.jsx'
import PhotoGallery from './pages/photo-gallery/photo-gallery.jsx'

import MenuList from './contents/menu-list.jsx'
import ContentList from './contents/content-list.jsx'
import ContentKML from './contents/content-kml.jsx'

import ContentDetail from './contents/content-detail.jsx'
import RequestedContentDetail from './contents/requested-content-detail.jsx'
import Commons from './commons'

import { AnalyticsRegister } from './analytics-register'
import { RaLog } from 'pikkart-remote-assistance'

export default [
  {
    path: '/',
    name: 'root',
    component: Landing,
    alias: '/index.html'
  },
  {
    name: 'splash',
    path: '/splash/',
    component: Splash
  },
  {
    name: 'main',
    path: '/main/',
    component: Main
  },
  {
    name: 'profilo',
    path: '/profilo',
    component: Profilo
  },
  {
    name: 'ar',
    path: '/ar',
    component: Ar
  },
  {
    name: 'arscenes-nootp',
    path: '/arscenes-nootp',
    component: ArSceneList
  },
  {
    name: 'discover',
    path: '/discover',
    component: Discover
  },
  {
    name: 'discover-views',
    path: '/discover-views',
    component: DiscoverViews
  },
  {
    name: 'discover-view-detail',
    path: '/discover-view-detail',
    component: DiscoverViewDetail
  },
  {
    name: 'discover-content-detail',
    path: '/discover-content-detail',
    component: DiscoverContentDetail
  },
  {
    name: 'arscenes',
    path: '/arscenes',
    component: ArSceneList
  },
  {
    name: 'settings',
    path: '/settings',
    component: Profilo
  },
  {
    name: 'Credits',
    path: '/credits',
    component: Credits
  },
  {
    name: 'Tutorial',
    path: '/tutorial',
    component: Tutorial
  },
  {
    name: 'login',
    path: '/login/',
    component: Login
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: Notifications
  },
  {
    name: 'ContactUs',
    path: '/contact',
    component: ContactUs
  },
  {
    name: 'Content-kml',
    path: '/content-kml',
    component: ContentKML
  },
  {
    name: 'Asset-Recognition',
    path: '/asset-recognition',
    component: AssetRecognition
  },
  {
    path: '/photo-gallery-result/',
    component: PhotoGallery
  },
  {
    path: '/content-detail/:contentId',
    component: ContentDetail,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },
  {
    path: '/requested-content-detail/:contentId',
    component: RequestedContentDetail,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },

  // Default route (404 page). MUST BE THE LAST
  {
    path: '(.*)',
    async: function (props) {
      const routeTo = props.to;
      const resolve = props.resolve;

      RaLog.log("routeTo=", routeTo);

      let menuStr = localStorage.getItem('menu');
      if (menuStr != null) {
        let menu = JSON.parse(localStorage.getItem('menu'));

        let menuItem = Commons.getMenuItemFromRoute(routeTo, menu);

        if (menuItem) {

          let resolveArgs = { component: menuItem.items.length > 0 ? MenuList : ContentList };
          let resolveOptions = { props: { 'menuItem': menuItem, ['c' + menuItem.catIndex]: menuItem.id } }

          AnalyticsRegister.setCurrentScreen(menuItem.title);
          resolve(resolveArgs, resolveOptions);
        }
        else {
          resolve({ component: ContentList });
        }
      }
      else {
        resolve({ component: ContentList });
      }
    }
  },
];
