import React from 'react';
import { CONTENT_VIEW } from 'pikkart-cms';
import { PkCordova, PkCordovaSharing } from 'pikkart-cordova';
import localeStrings from './detail-view-local';
import { Card, CardContent, Link, Block } from 'framework7-react';
import ContentHeader from '../content-views/content-elements/content-header';
import InfoContentView from '../content-views/info-content-view';
import ArSceneView from '../content-views/ar-scene-view/ar-scene-view';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../analytics-register';
import Commons from '../commons';
export class DetailView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            correlateInfoContentsData: null,
            isFavoriteContent: false,
            starIcon: 'star',
        };
    }

    render() {

        const item = this.props.item;

        return (
            <div>
                <Block style={{ padding: 0, margin: 0 }}>
                    <ContentHeader
                        showCloseButton={this.props.showCloseButton}
                        closeButtonFn={this.props.closeButtonFn}
                        content={item.content} />
                    <p />
                    {this.getContentViewLayout(item)}
                </Block>
            </div>
        );
    }

    errorRetrievingContent = (
        <Block style={{ padding: 0, margin: 0 }}>
            <Link
                cardClose
                color="Black"
                className="card-opened-fade-in"
                style={{ position: 'absolute', right: '15px', top: '15px' }}
                iconF7="xmark_circle_fill" />
            <Card>
                <CardContent style={{ textAlign: 'center' }}>
                    error retreiving content
            </CardContent>
            </Card>
        </Block>
    )

    getContentViewLayout = (item) => {
        if (item) {
            switch (item.content.ctnView) {
                case CONTENT_VIEW.DISCOVER_MODEL:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.INFOCONTENT:
                    return this.getInfoContentViewTab(item.content);
                case CONTENT_VIEW.MARKERLESS:
                    return this.getInfoContentViewTab(item.content);
                case CONTENT_VIEW.DISCOVER_KEYPOINT:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.PLACE:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.ITINERARY:
                    return this.errorRetrievingContent;
                case CONTENT_VIEW.AR_SCENE:
                    return this.getArScenesContentViewTab(item.content);
                default:
                    return this.errorRetrievingContent;
            }
        }
    }

    getInfoContentViewTab = (content) => {

        return <InfoContentView
            content={content}
            shareContentHandler={this.shareContent}
            galleryTitle={localeStrings.gallery_title} />
    }

    getArScenesContentViewTab = (content) => {
        return <ArSceneView
            content={content}
            arSceneButtonText={localeStrings.start_ar_button}
            shareContentHandler={this.shareContent}
            galleryTitle={localeStrings.gallery_title}
            currentCompany={this.props.currentCompany} />
    }

    shareContent = (url) => {
        if (PkCordova.isCordova()) {
            PkCordovaSharing.share(null, null, null, url);

            const item = this.props.item;
            AnalyticsRegister.logEvent(
                ANALYTICS_EVENT.SHARE_URL_CLICK,
                {
                    ContentName: item.content.title,
                    ContentId: item.content.id,
                    ContentType: Commons.getStringContentView(item.content.ctnView),
                    Url: item.content.googleUrl
                });
        }
    }
}


export default DetailView;