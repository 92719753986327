import React, { useRef } from 'react';
import { BlockTitle, SwiperSlide, Swiper, Block, PhotoBrowser } from 'framework7-react';

const ImageGallery = (props) => {

    const standalone = useRef(null);
    if (props.imageArray.length === 0)
        return null;

    const photos = props.imageArray.map((imageUrl, index) => {
        return (imageUrl && imageUrl !== "") ? imageUrl : 'https://picsum.photos/200';
    });

    const ret = props.imageArray.map((imageUrl, index) => {
        const gImageUrl = (imageUrl && imageUrl !== "") ? imageUrl : 'https://picsum.photos/200';

        return (
            //<div key={'image-' + index} style={{ width: '100%', height: '100%', padding: 'inherit' }} onClick={() => { imageClick(index); }} >
            <SwiperSlide key={'image-' + index} style={{ backgroundImage: "url(" + gImageUrl + ")" }} />
            //</div>
        )
    });
    return (
        <div>
            <BlockTitle medium>
                {props.galleryTitle}
            </BlockTitle>
            <Block>
                <Swiper
                    className="galleria"
                    pagination
                    params={{
                        spaceBetween: 10,
                        slidesPerView: 3,
                    }}>

                    <PhotoBrowser toolbar={false} theme="dark" photos={photos} ref={standalone}/>
                    {ret}
                </Swiper>
            </Block>
        </div>)
}

export default ImageGallery;