import React from 'react';
import { connect } from 'react-redux';
import { Block, BlockHeader, Page, Navbar } from 'framework7-react';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register';
import Commons from '../../commons';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import { PkCmsDiscover } from 'pikkart-cms';
import { RaApiAuth } from 'pikkart-remote-assistance';

class Discover extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            models: []
        };
    }

    /**
     * Funzione che permette di visualizzare le views del model
     * 
     * @param {number} id id del model
     */
    modifyModel(id) {
        let temp = this.state.models.filter(x => { return x.model.id === id })[0];
        this.props.f7router.navigate('/discover-views', { props: { model: temp }, reloadAll: true });
    }

    /**
     * Funzione che chiama l'api che restituisce i models
     */
    fetchModels = () => {
        var filters = {
            ow: this.props.appCompany.Id
        }

        PkCmsDiscover.getModelsDataPage(filters)
            .then((res) => {
                this.setState({ models: res.data.items });
            },
                (error) => {
                    console.log(error);
                });
    }

    render() {
        const models_list = this.state.models.map((e) => {
            return (
                <div id={"model-" + e.model.id} key={e.model.uniqueId} className="card card-model-dimension">
                    <div className="card-content" onClick={() => { this.modifyModel(e.model.id) }}>
                        <div>
                            {
                                e.contentData.content.imageUrl !== "" ?
                                    <img src={e.contentData.content.imageUrl} width="100%" className="lazy lazy-fade-in" />
                                    :
                                    <span className="material-icons material-icons-image">image</span>
                            }
                        </div>
                    </div>
                    <div className="card-footer">
                        {e.contentData.content.title}
                    </div>
                </div>
            )
        });
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={this.props.menu[2].title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                </Navbar>
                <BlockHeader></BlockHeader>
                <Block>
                    <div className="model-container">
                        {models_list}
                    </div>
                </Block>
            </Page>
        )
    }

    componentDidMount = () => {
        const self = this;

        if (Commons.checkAuthentication(this, this.props.f7router, "/discover")) {
            setTimeout(() => {
                self.props.f7router.navigate('/login/', { props: { redirectPage: "/discover" }, reloadAll: true });
            }, 500)
        }

        if (RaApiAuth.isAuthenticated() && this.props.appCompany) {
            this.fetchModels();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.appCompany != this.props.appCompany) {
            this.fetchModels();
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        appCompany: state.remoteAssistance.appCompany,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Discover);