import React from 'react';
import { connect } from 'react-redux';
import { Page, Navbar } from 'framework7-react';
import TutorialHelper from '../../helpers/tutorial-helper';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';

class Tutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);



        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                </Navbar>
                {TutorialHelper.getMenuTutorial()}
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.TUTORIAL);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);