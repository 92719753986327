import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, List, ListItem, AccordionContent, ListInput, Button, Popup, NavRight, Link, Preloader, f7, Icon } from 'framework7-react';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import Webcam from 'webcam-easy';
import { PkCmsDiscover } from 'pikkart-cms';
import { PkCordova } from 'pikkart-cordova';

class DiscoverContentDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pictureSource: null,
            destinationType: null,
            webcam: null,
            title: "",
            descr: "",
            imageBase64: "",
            imageWidth: 0,
            imageHeight: 0,
            saving: false,
            reRender: false
        }

        this.infoContent = {
            title: "",
            descr: "",
            imageBase64: "",
            date: "",
            webUrl: "",
            email: "",
            phone: "",
            fax: "",
            attchmBase64: "",
            attchmName: "",
            attchmDescr: "",
            youtubeUrl: "",
            youtubeName: "",
            audioBase64: "",
            googleUrl: "",
            pinUrl: "",
            twUrl: "",
            fbUrl: "",
            linkedinUrl: "",
            instUrl: "",
            skype: "",
            price: 0,
            ecommerceUrl: ""
        }
    }

    /**
     * Funzione che restituisce il base64 dell'immagine selezionata o scattata
     * 
     * @param {string} imageData base64 dell'immagine
     */
    onPhotoDataSuccess(imageData) {
        let imagebase64 = "data:image/png;base64," + imageData;

        let img = document.getElementById("temp_image");
        img.src = imagebase64;
    }

    /**
     * Funzione che restituisce un errore in caso di non riuscita cattura dell'immagine selezionata o scattata
     * 
     * @param {string} message messaggio d'errore
     */
    onFail(message) {
        f7.toast.create({
            text: 'Failed because: ' + message,
            closeTimeout: 2000,
        }).open();
    }

    /**
     * Funzione che aspetta che l'immagine venga caricata
     */
    waitForElement = () => {
        if (document.getElementById("temp_image").src != "") {
            let imageBase64 = document.getElementById("temp_image").src;
            this.infoContent.imageBase64 = imageBase64;
            this.setState({ imageBase64: imageBase64 });
            if (!this.props.newContent && this.infoContent.imageUrl) {
                delete this.infoContent.imageUrl;
            }
        } else {
            setTimeout(() => {
                this.waitForElement();
            }, 1500);
        }
    }

    /**
     * Funzione che crea una nuova view con immagine dalla camera
     */
    openCamera = () => {
        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL
            });

            this.waitForElement();
        } else {
            let picture = this.state.webcam.snap();

            this.imageDimensions(picture);

            this.infoContent.imageBase64 = picture;
            this.setState({ imageBase64: picture });

            if (!this.props.newContent && this.infoContent.imageUrl) {
                delete this.infoContent.imageUrl;
            }
        }
    }

    /**
     * Funzione che crea una nuova view con immagine dalla galleria
     * 
     * @param {object} e oggetto che contiene informazioni sull'immagine selezionata
     */
    openGallery = (e) => {
        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;
            let pictureSource = this.state.pictureSource;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL,
                sourceType: pictureSource.PHOTOLIBRARY
            });
            this.waitForElement();
        } else {
            let base64 = "";
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener('load', () => {
                base64 = reader.result;

                if (base64.substr(11, 4) == "jpeg") {
                    base64 = "data:image/png;base64," + base64.substr(23);
                }

                this.imageDimensions(base64);

                this.infoContent.imageBase64 = base64.split(",")[1];
                this.setState({ imageBase64: base64 });
            });

            if (!this.props.newContent && this.infoContent.imageUrl) {
                delete this.infoContent.imageUrl;
            }
        }
    }

    /**
     * Funzione che avvia la webcam del pc
     */
    startWebcam = () => {
        let webcamElement = document.getElementById('webcam');
        let canvas = document.getElementById('temp_canvas');
        webcamElement.width = document.getElementById('webcam-container').getBoundingClientRect().width;
        webcamElement.height = document.getElementById('webcam-container').getBoundingClientRect().width * (3 / 4);
        let webcam = new Webcam(webcamElement, 'user', canvas);
        this.setState({ webcam: webcam });
        webcam.start()
            .then((result) => {
                console.log("webcam started");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Funzione che calcola la grandezza dell'immagine e la converte
     */
    imageDimensions = (base64) => {
        let image = new Image();

        image.onload = () => {
            let newImageWidth = document.getElementById("info-content-image").getBoundingClientRect().width;
            let newImageHeight = document.getElementById("info-content-image").getBoundingClientRect().width * image.height / image.width;
            this.setState({ imageWidth: newImageWidth, imageHeight: newImageHeight });
        }

        image.src = base64;
    }

    /**
     * Funzione che converte un file in una stringa base64
     * 
     * @param {object} file file da convertire
     * @param {string} param posizione dove salvare il base64
     */
    toBase64 = (file, param) => {
        document.getElementById("saveButton").disabled = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            switch (param) {
                case "attchmBase64":
                    this.infoContent.attchmBase64 = reader.result.split(",")[1];
                    break;
                case "audioBase64":
                    this.infoContent.audioBase64 = reader.result.split(",")[1];
                    break;

                default:
                    break;
            }

            if (this.infoContent.title && this.infoContent.descr) {
                document.getElementById("saveButton").disabled = false;
            }
        }
    }

    /**
     * Funzione che salva l'info content
     */
    saveContent = () => {
        this.setState({ saving: true });
        document.getElementById("preloader").style.display = 'block';
        document.getElementById("saveButton").style.display = 'none';
        document.getElementById("deleteButton").style.display = 'none';

        if (this.props.newContent) {
            PkCmsDiscover.addModelContent(this.props.model.model.contentId, this.infoContent)
                .then(res => {
                    document.getElementById("preloader").style.display = 'none';
                    document.getElementById("saveButton").style.display = 'block';
                    document.getElementById("deleteButton").style.display = 'block';

                    if (res.result.code != 200) {
                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    } else {
                        this.props.f7router.navigate('/discover-views', { props: { model: this.props.model, activeContentTab: true }, reloadAll: true });
                    }
                });
        } else {
            PkCmsDiscover.setModelContent(this.props.infoContent.id, this.infoContent)
                .then(res => {
                    document.getElementById("preloader").style.display = 'none';
                    document.getElementById("saveButton").style.display = 'block';
                    document.getElementById("deleteButton").style.display = 'block';

                    if (res.result.code != 200) {
                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    } else {
                        this.props.f7router.navigate('/discover-views', { props: { model: this.props.model, activeContentTab: true }, reloadAll: true });
                    }
                });
        }
    }

    render() {
        return (
            <Page>
                <Navbar>
                    <span className="material-icons icon-arrow-back" onClick={() => { this.props.f7router.navigate('/discover-views', { props: { model: this.props.model, activeContentTab: true }, reloadAll: true }) }}>arrow_back</span>
                    <PikkartNavbar
                        title={this.props.model.contentData.content.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                </Navbar>
                <Block>
                    <List accordionList noHairlines inlineLabels style={{ fontSize: "1.1rem" }}>
                        <ListItem accordionItem accordionItemOpened title="Base Informations">
                            <AccordionContent>
                                <ListInput
                                    label="Title"
                                    type="text"
                                    placeholder="Title"
                                    name="title"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.title : ""}
                                    maxlength="200"
                                    required
                                    onBlur={(e) => { this.infoContent.title = e.target.value; this.setState({ title: e.target.value }) }}
                                    clearButton />
                                <ListInput
                                    label="Description"
                                    type="text"
                                    placeholder="Description"
                                    name="descr"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.descr : ""}
                                    required
                                    onBlur={(e) => { this.infoContent.descr = e.target.value; this.setState({ descr: e.target.value }) }}
                                    clearButton />
                                <ListInput
                                    input={false}
                                    label="Image"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.imageBase64 : ""}>
                                    <div slot="input">
                                        <div
                                            id="info-content-image"
                                            className="info-content-image"
                                            style={{
                                                display: this.state.imageBase64 == "" ? "none" : "block",
                                                width: "50%",
                                            }}>
                                            {this.props.modifyInfoContent ?
                                                this.props.infoContent.imageUrl != "" ?
                                                    <img src={this.props.infoContent.imageUrl} alt="Image not found" style={{ maxWidth: "100%" }} />
                                                    :
                                                    <></>
                                                :
                                                <img src={this.state.imageBase64} alt="Image not found"
                                                    style={{
                                                        width: this.state.imageWidth,
                                                        height: this.state.imageHeight
                                                    }} />
                                            }
                                        </div>
                                        <div className="image-button-container">
                                            {this.state.imageBase64 == "" ?
                                                <>
                                                    <Button
                                                        iconMaterial="photo_camera"
                                                        popupOpen=".info-camera-popup"
                                                        onClick={() => { this.startWebcam() }}>Camera</Button>
                                                    <input id="infoOpenGallery" type="file" onChange={(e) => { this.openGallery(e) }} style={{ display: "none" }} />
                                                    <Button
                                                        iconMaterial="collections"
                                                        onClick={() => { document.getElementById("infoOpenGallery").click() }}>Gallery</Button>
                                                </>
                                                :
                                                <Button
                                                    iconF7="trash_circle"
                                                    onClick={() => {
                                                        this.setState({ imageBase64: "", imageWidth: 0, imageHeight: 0 });
                                                        this.infoContent.imageBase64 = "";
                                                        if (!this.props.newContent) {
                                                            this.infoContent.imageUrl = null;
                                                        }
                                                    }}>
                                                    Remove
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </ListInput>
                                <ListInput
                                    label="Date"
                                    type="datepicker"
                                    placeholder="Enter Date"
                                    name="date"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.date : ""}
                                    calendarParams={{ closeOnSelect: true, closeByOutsideClick: true }}
                                    onCalendarChange={(e) => { this.infoContent.date = new Date(e[0]).getTime() }}
                                    clearButton />
                                <ListInput
                                    label="URL"
                                    type="url"
                                    placeholder="URL"
                                    name="webUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.webUrl : ""}
                                    onBlur={(e) => { this.infoContent.webUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem accordionItem title="Contacts">
                            <AccordionContent>
                                <ListInput
                                    label="E-mail"
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.email : ""}
                                    onBlur={(e) => { this.infoContent.email = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Phone"
                                    type="tel"
                                    placeholder="Phone"
                                    name="phone"
                                    pattern="^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.phone : ""}
                                    onBlur={(e) => { this.infoContent.phone = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Fax"
                                    type="text"
                                    placeholder="Fax"
                                    name="fax"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.fax : ""}
                                    onBlur={(e) => { this.infoContent.fax = e.target.value }}
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem accordionItem title="Attachments">
                            <AccordionContent>
                                {this.infoContent.attchmBase64 == "" ?
                                    <ListInput
                                        label="Attachment"
                                        type="file"
                                        accept=".pdf"
                                        placeholder="Attachment"
                                        name="attchmBase64"
                                        defaultValue={this.props.infoContent ? this.props.infoContent.attchmUrl : ""}
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                this.infoContent.attchmBase64 = this.toBase64(e.target.files[0], "attchmBase64");
                                                document.getElementsByName("attchmName")[0].value = e.target.files[0].name.split(".")[0];
                                                this.infoContent.attchmName = e.target.files[0].name.split(".")[0];

                                                if (!this.props.newContent && this.infoContent.attchmUrl) {
                                                    delete this.infoContent.attchmUrl;
                                                }
                                                this.setState({ reRender: !this.state.reRender });
                                            }
                                        }}
                                    />
                                    :
                                    <ListInput
                                        input={false}
                                        label="Attachment"
                                        name="attchmBase64"
                                    >
                                        <div slot="input" style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                            <Icon f7="cloud_download" style={{ color: "var(--f7-theme-color)", display: 'flex', alignSelf: 'center' }} />
                                            <Button
                                                iconF7="trash_circle"
                                                onClick={() => {
                                                    this.infoContent.attchmBase64 = "";
                                                    document.getElementsByName("attchmName")[0].value = "";
                                                    this.infoContent.attchmName = "";

                                                    if (!this.props.newContent) {
                                                        this.infoContent.attchmUrl = null;
                                                    }
                                                    this.setState({ reRender: !this.state.reRender });
                                                }}
                                            >Remove</Button>
                                        </div>
                                    </ListInput>
                                }
                                <ListInput
                                    label="File Name"
                                    type="text"
                                    placeholder="File Name"
                                    name="attchmName"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.attchmName : ""}
                                    onChange={(e) => { this.infoContent.attchmName = e.target.value }}
                                    clearButton
                                />
                                <ListInput
                                    label="Description"
                                    type="text"
                                    placeholder="Description"
                                    name="attchmDescr"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.attchmDescr : ""}
                                    onBlur={(e) => { this.infoContent.attchmDescr = e.target.value }}
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem accordionItem title="Media">
                            <AccordionContent>
                                <ListInput
                                    label="Youtube Url"
                                    type="url"
                                    placeholder="Youtube Url"
                                    name="youtubeUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.youtubeUrl : ""}
                                    onBlur={(e) => { this.infoContent.youtubeUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Description"
                                    type="text"
                                    placeholder="Description"
                                    name="youtubeName"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.youtubeName : ""}
                                    onBlur={(e) => { this.infoContent.youtubeName = e.target.value }}
                                    clearButton
                                />
                                {this.infoContent.audioBase64 == "" ?
                                    <ListInput
                                        label="Audio (mp3)"
                                        type="file"
                                        accept=".mp3"
                                        placeholder="Audio (mp3)"
                                        name="audioBase64"
                                        defaultValue={this.props.infoContent ? this.props.infoContent.audioUrl : ""}
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                this.infoContent.audioBase64 = this.toBase64(e.target.files[0], "audioBase64");
                                                this.setState({ title: this.infoContent.title });

                                                if (!this.props.newContent) {
                                                    delete this.infoContent.audioUrl;
                                                }
                                            }
                                        }}
                                        clearButton
                                    />
                                    :
                                    <ListInput
                                        input={false}
                                        label="Audio (mp3)"
                                        name="audioBase64"
                                    >
                                        <div slot="input" style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                            <Icon f7="cloud_download" style={{ color: "var(--f7-theme-color)", display: 'flex', alignSelf: 'center' }} />
                                            <Button
                                                iconF7="trash_circle"
                                                onClick={() => {
                                                    this.infoContent.audioBase64 = ""
                                                    this.setState({ title: this.infoContent.title });

                                                    if (!this.props.newContent && this.infoContent.audioUrl) {
                                                        this.infoContent.audioUrl = null;
                                                    }
                                                }}
                                            >Remove</Button>
                                        </div>
                                    </ListInput>
                                }

                            </AccordionContent>
                        </ListItem>
                        <ListItem accordionItem title="Social">
                            <AccordionContent>
                                <ListInput
                                    label="Share Url"
                                    type="url"
                                    placeholder="Share Url"
                                    name="googleUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.googleUrl : ""}
                                    onBlur={(e) => { this.infoContent.googleUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Pinterest Url"
                                    type="url"
                                    placeholder="Pinterest Url"
                                    name="pinUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.pinUrl : ""}
                                    onBlur={(e) => { this.infoContent.pinUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Twitter Url"
                                    type="url"
                                    placeholder="Twitter Url"
                                    name="twUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.twUrl : ""}
                                    onBlur={(e) => { this.infoContent.twUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Facebook Url"
                                    type="url"
                                    placeholder="Facebook Url"
                                    name="fbUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.fbUrl : ""}
                                    onBlur={(e) => { this.infoContent.fbUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Linkedin Url"
                                    type="url"
                                    placeholder="Linkedin Url"
                                    name="linkedinUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.linkedinUrl : ""}
                                    onBlur={(e) => { this.infoContent.linkedinUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Instagram Url"
                                    type="url"
                                    placeholder="Instagram Url"
                                    name="instUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.instUrl : ""}
                                    onBlur={(e) => { this.infoContent.instUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="Skype"
                                    type="url"
                                    placeholder="Skype"
                                    name="skype"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.skype : ""}
                                    onBlur={(e) => { this.infoContent.skype = e.target.value }}
                                    validate
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                        <ListItem accordionItem title="E-commerce">
                            <AccordionContent>
                                <ListInput
                                    label="Price"
                                    type="number"
                                    placeholder="Price"
                                    name="price"
                                    step="any"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.price == 0 ? "" : this.props.infoContent.price : ""}
                                    onBlur={(e) => { this.infoContent.price = e.target.value }}
                                    validate
                                    clearButton
                                />
                                <ListInput
                                    label="E-commerce Url "
                                    type="url"
                                    placeholder="E-commerce Url "
                                    name="e-commerceUrl"
                                    defaultValue={this.props.infoContent ? this.props.infoContent.ecommerceUrl : ""}
                                    onBlur={(e) => { this.infoContent.ecommerceUrl = e.target.value }}
                                    validate
                                    clearButton
                                />
                            </AccordionContent>
                        </ListItem>
                    </List>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 2%' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Preloader id="preloader" color="multi" size="30" style={{ display: 'none', paddingRight: '28px' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                id="deleteButton"
                                className="open-confirm"
                                style={{ fontSize: "1rem", width: "100px", height: "40px" }}
                                iconF7="trash"
                                onClick={() => {
                                    f7.dialog.confirm(
                                        'Do you want to delete this content?',
                                        'Delete',
                                        () => {
                                            if (!this.props.newContent) {
                                                PkCmsDiscover.deleteModelContent(this.props.infoContent.id);
                                            }
                                            this.props.f7router.navigate('/discover-views', { props: { model: this.props.model, activeContentTab: true }, reloadAll: true });
                                        });
                                }}>Delete</Button>
                            <Button
                                id="saveButton"
                                disabled={this.state.title != "" && this.state.descr != "" ? false : true}
                                style={{ fontSize: "1rem", width: "100px", height: "40px" }}
                                iconF7="checkmark_alt_circle"
                                onClick={() => { this.saveContent() }}>Save</Button>
                        </div>
                    </div>
                    <img id="temp_image" style={{ display: "none" }} />
                    <canvas id="temp_canvas" style={{ display: "none" }} />
                </Block>
                <Popup className="info-camera-popup">
                    <Page>
                        <Navbar title="Camera">
                            <NavRight>
                                <Link popupClose
                                    onClick={() => { this.state.webcam.stop() }}>Close</Link>
                            </NavRight>
                        </Navbar>
                        <Block>
                            <div id="webcam-container">
                                <video id="webcam" autoPlay playsInline></video>
                            </div>
                            <button className="col button button-raised popup-close" onClick={() => { this.openCamera() }} style={{ width: "35%", marginTop: "5%" }}>Take picture</button>
                        </Block>
                    </Page>
                </Popup>
            </Page>
        )
    }

    componentDidMount = () => {
        if (this.props.infoContent) {
            this.infoContent = this.props.infoContent;
            this.infoContent.attchmBase64 = this.props.infoContent.attchmUrl;
            this.infoContent.audioBase64 = this.props.infoContent.audioUrl;
            this.setState({
                title: this.props.infoContent.title,
                descr: this.props.infoContent.descr
            })
        }

        this.setState({ imageBase64: this.infoContent.imageBase64 });

        if (PkCordova.isCordova()) {
            this.setState({
                pictureSource: navigator.camera.PictureSourceType,
                destinationType: navigator.camera.DestinationType
            });
        }
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverContentDetail);