import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        name: "Name",
        name_placeholder: "Your name",
        email: "Email",
        email_placeholder: "Your e-mail",
        subject: "Subject",
        subject_placeholder: "Enter the subject",
        message: "Message",
        message_placeholder: "Enter your message",
        submit_button_text: "Submit",
    },
    it: {
        name: "Nome",
        name_placeholder: "Il tuo nome",
        email: "Email",
        email_placeholder: "La tua e-mail",
        subject: "Oggetto",
        subject_placeholder: "Inserisci l'oggetto",
        message: "Messaggio",
        message_placeholder: "Inserisci il tuo messaggio",
        submit_button_text: "Invia",
    }
});

export default localeStrings;