import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, ListInput, Sheet, Icon, ListItem, f7 } from 'framework7-react';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import Commons from '../../commons';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import Draggable from 'react-draggable';
import { PkCmsDiscover } from 'pikkart-cms';
import CanvasDraw from "react-canvas-draw";

class DiscoverViewDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            poi: [],
            popoverOptions: [],
            contentGroup: [
                { id: 1, name: "Scheda Informativa" },
                { id: 3, name: "Luogo" },
                { id: 5, name: "Business Card" },
                { id: 6, name: "Company" },
                { id: 7, name: "Discover Model" },
                { id: 8, name: "Markerless" },
                { id: 9, name: "Discover Key Point" },
                { id: 10, name: "Itinerario" },
                { id: 11, name: "Scena AR" }],
            selectedPoI: null,
            imageWidth: 0,
            imageHeight: 0,
            lastX: 0,
            lastY: 0,
            newPoI: false,
            movement: false,
            prevContentId: null,
            colorPickerClosed: false,
            error: false
        }
        this.sigCanvas = null;
        this.deleteView = this.deleteView.bind(this);
    }

    /**
     * Funzione che crea un nuovo punto di interesse
     * 
     * @param {object} e evento del click sull'immagine per la creazione di un punto di interesse 
     */
    createPoI = (e) => {
        if (!this.state.selectedPoI && e.target.id == "pois-container") {
            this.resetSelect("");
            let temp_posX = window.innerWidth <= 1024 ?
                e.pageX - document.querySelector("#pois-container").offsetLeft - 16 - window.getComputedStyle(document.getElementById("pois-image")).marginLeft.substr(0, -2) - 10
                :
                (e.pageX - document.querySelector("#pois-container").offsetLeft - 16 - window.getComputedStyle(document.getElementById("pois-image")).marginLeft.substr(0, -2) - 10) - (window.innerWidth * 15 / 100);
            let temp_posY = e.pageY - document.querySelector("#pois-container").offsetTop - document.querySelector(".block").offsetTop - 10;
            let posX = temp_posX * this.state.imageWidth / document.getElementById("pois-image").getBoundingClientRect().width;
            let posY = temp_posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height;
            document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].style.display = "none";
            document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].addEventListener("click", () => {
                document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].click();
            });
            let tempnewpoi = {
                keypointUniqueId: "",
                contentId: "",
                posX: posX,
                posY: posY,
                color: "#ff0000",
                viewUniqueId: this.props.view.uniqueId
            };
            this.setState({
                poi: [...this.state.poi, tempnewpoi],
                selectedPoI: tempnewpoi,
                newPoI: true
            });
            document.getElementById("open-sheet").click();
        }
    }

    /**
     * Funzione per cambiare colore al punto di interesse
     * 
     * @param {object} e evento del click per cambiare colore
     */
    setColorSelectedPoI = (e) => {
        if (this.state.selectedPoI) {
            if (!this.state.error) {
                this.state.selectedPoI.color = e.hex;
                this.state.poi.filter(x => { return x.keypointUniqueId == this.state.selectedPoI.keypointUniqueId })[0].color = e.hex;

                if (document.getElementsByClassName("color-picker-popover").length > 0 && !this.state.colorPickerClosed) {
                    this.setState({ colorPickerClosed: true });
                    f7.colorPicker.get('.color-picker').on('close', () => {
                        this.savePoI();
                    })
                }
            }
        }
    }

    /**
     * Funzione che salva il punto di interesse
     */
    savePoI = () => {
        let posX = this.state.selectedPoI.posX * this.state.imageWidth / document.getElementById("pois-image").getBoundingClientRect().width;
        let posY = this.state.selectedPoI.posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height;

        if (this.state.selectedPoI.contentId != "") {
            if (this.state.newPoI) {
                PkCmsDiscover.saveModelViewKeyPoint(
                    this.props.view.uniqueId,
                    this.state.selectedPoI.contentId,
                    this.state.selectedPoI.posX,
                    this.state.selectedPoI.posY,
                    this.state.selectedPoI.color
                ).then(res => {
                    if (res.result.success) {
                        this.setState({ newPoI: false });
                    } else {
                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    }
                });
            } else if (!this.state.movement) {
                PkCmsDiscover.saveModelViewKeyPoint(
                    this.props.view.uniqueId,
                    this.state.selectedPoI.contentId,
                    this.state.selectedPoI.posX,
                    this.state.selectedPoI.posY,
                    this.state.selectedPoI.color
                ).then(res => {
                    if (res.result.success) {
                        this.setState({ error: false });
                        if (this.state.prevContentId != this.state.selectedPoI.contentId) {
                            PkCmsDiscover.deleteModelViewKeyPoint(this.props.view.uniqueId, this.state.prevContentId);
                        }
                    } else {
                        this.setState({ error: true });

                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    }
                });
            } else {
                PkCmsDiscover.saveModelViewKeyPoint(
                    this.props.view.uniqueId,
                    this.state.selectedPoI.contentId,
                    posX,
                    posY,
                    this.state.selectedPoI.color
                ).then(res => {
                    if (res.result.success) {
                        this.setState({ movement: false, error: false });
                    } else {
                        this.setState({ error: true });

                        f7.toast.create({
                            text: 'Saving Error',
                            closeTimeout: 2000,
                        }).open();
                    }
                });
            }
        }
    }

    /**
     * Funzione per cambiare il valore di default e disabilita opzioni del tag select
     * 
     * @param {string} value valore che seleziona la option selezionata
     */
    resetSelect = (value) => {
        var sel = document.getElementById("selectContentId");
        var opts = sel.options;
        for (let opt, j = 0; opt = opts[j]; j++) {
            this.state.poi.forEach(e => {
                if (e.contentId == opt.value && value != opt.value) {
                    opt.disabled = true;
                }
            });

            if (value != "" && opt.value == "") {
                opt.disabled = true;
            }

            if (value == "" && opt.value == "") {
                opt.disabled = false;
            }

            if (opt.value == value) {
                sel.selectedIndex = j;
                opt.disabled = false;
                if (document.getElementsByClassName("item-after")[0]) {
                    document.getElementsByClassName("item-after")[0].innerHTML = opt.text
                }
            }
        }
    }

    /**
     * Funzione che resetta il punto di interesse nel caso non fosse selezionato
     */
    resetPoI = () => {
        let temp_poi = this.state.poi.filter(x => { return x.contentId != "" });
        if (this.state.newPoI) {
            temp_poi = temp_poi.filter(x => { return x.contentId != this.state.selectedPoI.contentId });
        }

        if (temp_poi) {
            this.setState({ selectedPoI: null, poi: temp_poi });
        } else {
            this.setState({ selectedPoI: null });
        }

        if (this.state.error) {
            PkCmsDiscover.getModelViewKeyPoints(this.props.view.uniqueId)
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.setState({ poi: res.data, error: false });
                    }
                });
        }

        if (document.getElementsByClassName("link popup-close")[0]) {
            document.getElementsByClassName("link popup-close")[0].click();
        }
    }

    /**
     * Funzione che chiama l'api per eliminare un punto di interesse
     */
    deletePoI = () => {
        let dom_selected_poi = null;
        if (this.state.selectedPoI.contentId != "") {
            dom_selected_poi = this.isHtmlElement(document.getElementById(this.state.selectedPoI.keypointUniqueId));
        }
        let temp_poi = this.state.poi.filter(x => { return x.contentId != this.state.selectedPoI.contentId });

        PkCmsDiscover.deleteModelViewKeyPoint(this.props.view.uniqueId, this.state.selectedPoI.contentId)
            .then(res => {
                if (res.result.success) {
                    this.setState({
                        poi: temp_poi,
                        selectedPoI: null
                    });

                    if (dom_selected_poi) {
                        this.props.f7router.navigate('/discover-view-detail', { props: { model: this.props.model, view: this.props.view, image: this.props.image, layer: this.props.layer }, reloadAll: true });
                    }
                }
            });

        if (dom_selected_poi) {
            this.props.f7router.navigate('/discover-views', { props: { model: this.props.model }, reloadAll: true });
        }
    }

    /**
     * Funzione che chiama l'api per elimanare una view
     */
    deleteView = () => {
        PkCmsDiscover.deleteModelView(this.props.view.uniqueId);

        this.props.f7router.navigate('/discover-views', { props: { model: this.props.model, deletedView: this.props.view.uniqueId }, reloadAll: true });
    }

    /**
     * Funzione che controlla se l'elemento passato è un html element
     * 
     * @param {object} o oggetto html
     * @returns true se l'oggetto è un html element o false se non lo è 
     */
    isHtmlElement = (o) => {
        return (
            typeof HTMLElement === "object" ? o instanceof HTMLElement :
                o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
        );
    }

    /**
     * Funzione che ritorna il valore della traslazione di un elemento
     * 
     * @param {object} element elemento html
     * @return oggetto con coordinate x e y della traslazione
     */
    getTranslateXY = (element) => {
        if (this.isHtmlElement(element)) {
            let style = window.getComputedStyle(element)
            let matrix = new DOMMatrixReadOnly(style.transform)
            return {
                x: matrix.m41,
                y: matrix.m42
            }
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }

    /**
     * Funzione che seleziona o salva il movimento di un punto di interesse
     * 
     * @param {object} e oggetto del click sul punto di interesse
     * @param {object} data oggetto con i valori delle coordinate del punto di interesse
     */
    handleStop(e, data) {
        let translate = this.getTranslateXY(e.target)
        let temp_poi = this.state.poi.filter(x => { return x.keypointUniqueId == e.target.id })[0];
        let posX;
        let posY
        if (temp_poi) {
            posX = temp_poi.posX * document.getElementById("pois-image").getBoundingClientRect().width / this.state.imageWidth;
            posY = temp_poi.posY * document.getElementById("pois-image").getBoundingClientRect().height / this.state.imageHeight;
        }
        // Gestione movimento o click del poi
        if (data.x == posX && data.y == posY || data.lastX == this.state.lastX && data.lastY == this.state.lastY) {
            this.resetSelect(e.target.dataset.contentid);
            this.setState({ selectedPoI: temp_poi });
            document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].style.display = "none";
            document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].addEventListener("click", () => {
                document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].click();
            });
            document.getElementById("open-sheet").click();
        } else {
            this.setState({ movement: true });
            temp_poi.posX = translate.x;
            temp_poi.posY = translate.y;
            if (this.state.selectedPoI) {
                this.savePoI();
            }
            this.setState({ selectedPoI: temp_poi, lastX: data.lastX, lastY: data.lastY });
        }
    }

    /**
     * Funzione che chiama l'api per salvare il layer
     */
    saveMask = () => {
        let layerbase64 = "";
        let canvas = "";
        document.querySelectorAll("canvas").forEach((e) => {
            if (e.style.zIndex == 11) {
                canvas = e;
            }
        });
        layerbase64 = canvas.toDataURL();
        setTimeout(() => {
            let newCanvas = document.createElement("canvas");
            let newCtx = newCanvas.getContext('2d');

            newCanvas.width = this.state.imageWidth;
            newCanvas.height = this.state.imageHeight;
            let image = new Image
            image.onload = function () {
                newCtx.drawImage(image, 0, 0, image.width, image.height,
                    0, 0, newCanvas.width, newCanvas.height);
            }
            image.src = layerbase64;

            setTimeout(() => {
                PkCmsDiscover.setModelViewMask(this.props.view.uniqueId, newCanvas.toDataURL().substr(22));
            }, 500)

        }, 500);
    }

    /**
     * Funzione che disegna il layer
     */
    drawLayer = () => {
        if (this.props.layer && this.props.layer != "file not found") {
            setTimeout(() => {
                let canvas = "";
                document.querySelectorAll("canvas").forEach((e) => {
                    if (e.style.zIndex == 11) {
                        canvas = e;
                    }
                });
                let ctx = canvas.getContext('2d');
                let image = new Image;
                image.onload = function () {
                    ctx.drawImage(image, 0, 0, image.width, image.height,
                        0, 0, canvas.width, canvas.height);
                }
                image.src = this.props.layer;
            }, 300);
        }
    }

    render() {
        let options = null;
        if (this.state.popoverOptions.length > 0) {
            let group = [];

            this.state.popoverOptions.forEach((e) => {
                if (!group[e.content.ctnView]) {
                    group[e.content.ctnView] = [];
                }

                group[e.content.ctnView].push({ e });
            });

            options = this.state.contentGroup.map((c) => {
                return (
                    group[c.id] != undefined ?
                        <optgroup key={c.id} label={c.name}>
                            {group[c.id].map((e) => {
                                return (
                                    <option key={e.e.content.id} value={e.e.content.id}>{e.e.content.title}</option>
                                )
                            })}
                        </optgroup>
                        :
                        <></>
                )
            });
        }
        let pois = null;
        if (this.state.poi.length > 0) {
            pois = this.state.poi.map((e, i) => {
                let posX = 0;
                let posY = 0;
                if (document.getElementById("pois-image")) {
                    posX = e.posX * document.getElementById("pois-image").getBoundingClientRect().width / this.state.imageWidth;
                    posY = e.posY * document.getElementById("pois-image").getBoundingClientRect().height / this.state.imageHeight;
                }
                return (
                    <Draggable
                        key={"poi-" + i}
                        axis="both"
                        handle={".handle-" + i}
                        bounds="parent"
                        defaultPosition={{
                            x: posX,
                            y: posY
                        }}
                        onStart={this.handleStart}
                        onDrag={this.handleDrag}
                        onStop={(e, data) => { this.handleStop(e, data) }}>
                        <div id={e.keypointUniqueId} data-contentid={e.contentId} className={"circle handle-" + i} style={{ position: "absolute", background: e.color }}></div>
                    </Draggable>
                )
            });
        }
        const image = new Image();
        image.onload = () => {
            this.setState({ imageWidth: image.width, imageHeight: image.height });
        }
        image.src = this.props.image;
        const self = this;
        return (
            <Page>
                <Navbar>
                    <span className="material-icons icon-arrow-back" onClick={() => { this.props.f7router.navigate('/discover-views', { props: { model: this.props.model }, reloadAll: true }) }}>arrow_back</span>
                    <PikkartNavbar
                        title={"View " + this.props.view.id}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                    <a className="link popover-open" data-popover=".popover-links">
                        <span className="material-icons icon-custom-more-vert">more_vert</span>
                    </a>
                    <div className="popover popover-links">
                        <div className="popover-inner">
                            <div className="list">
                                <ul>
                                    <li><a className="list-button item-link popover-close" href="#" onClick={() => { this.deleteView() }}>Delete</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Navbar>
                <div className="toolbar tabbar toolbar-bottom">
                    <div className="toolbar-inner">
                        <a href="#tab-poi" className="tab-link tab-link-active"
                            onClick={() => {
                                document.getElementById("mask-buttons").style.display = "none";
                                this.sigCanvas ? this.sigCanvas.clear() : <></>;
                            }}>PoI</a>
                        <a href="#tab-mask" className="tab-link"
                            onClick={() => {
                                document.getElementById("mask-buttons").style.display = "block";
                                document.addEventListener("mousedown", (e) => {
                                    if (e.target.tagName == "CANVAS") {
                                        document.getElementById("mask-buttons").style.display = "none";
                                    }
                                });
                                document.addEventListener("mouseup", (e) => {
                                    if (e.target.tagName == "CANVAS") {
                                        document.getElementById("mask-buttons").style.display = "block";
                                    }
                                });
                                document.addEventListener("touchstart", (e) => {
                                    if (e.target.tagName == "CANVAS") {
                                        document.getElementById("mask-buttons").style.display = "none";
                                    }
                                });
                                document.addEventListener("touchend", (e) => {
                                    if (e.target.tagName == "CANVAS") {
                                        document.getElementById("mask-buttons").style.display = "block";
                                    }
                                });
                                this.drawLayer();
                            }}>Mask</a>
                    </div>
                </div>
                <Block>
                    <div className="tabs-animated-wrap">
                        <div className="tabs">
                            <div id="tab-poi" className="tab tab-active">
                                <div className="poi-container">
                                    <div className="image-container">
                                        <img id="pois-image" src={this.props.image} alt="image not found"
                                            style={{
                                                maxWidth: window.innerWidth / 1.1,
                                                maxHeight: window.innerHeight / 1.5,
                                                margin: "auto"
                                            }} />
                                        <div id="pois-container" className="bg-canvas"
                                            style={{
                                                position: "absolute",
                                                width: document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().width : window.innerWidth / 1.1,
                                                height: document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().height : window.innerHeight / 1.5
                                            }}
                                            onClick={(e) => { this.createPoI(e) }} >
                                            {pois}
                                        </div>
                                    </div>
                                </div>
                                <Sheet
                                    id="sheet-modal-poi"
                                    className="my-sheet-swipe-to-close sheet-style"
                                    backdrop={true}
                                    swipeToClose
                                    closeByBackdropClick
                                    onSheetClosed={() => { this.resetPoI() }}>
                                    <div className="page-content">
                                        <div className="block-title block-title-large">Point of Interest</div>
                                        <div className="block">
                                            <div className="list simple-list">
                                                <ul id="list-poi">
                                                    <ListItem
                                                        title="Content"
                                                        className="padding-left-0 list-item-poi"
                                                        smartSelect
                                                        smartSelectParams={{
                                                            openIn: 'popup',
                                                            searchbar: true,
                                                            searchbarPlaceholder: 'Search Content',
                                                            closeOnSelect: true,
                                                            sheetBackdrop: true
                                                        }}>
                                                        <select id="selectContentId" name="contentId"
                                                            onChange={(e) => {
                                                                this.state.prevContentId = this.state.selectedPoI.contentId;
                                                                this.state.selectedPoI.contentId = e.target.value;
                                                                this.savePoI()
                                                            }}>
                                                            <option key="" value="">---</option>
                                                            {options}
                                                        </select>
                                                    </ListItem>
                                                    <ListInput
                                                        className="padding-left-0 colorpicker-custom"
                                                        type="colorpicker"
                                                        label="Color"
                                                        inlineLabel={true}
                                                        readonly
                                                        closeByBackdropClick
                                                        value={{ hex: this.state.selectedPoI ? this.state.selectedPoI.color : "#ffff00" }}
                                                        onColorPickerChange={(e) => { this.setColorSelectedPoI(e) }}
                                                    >
                                                        <Icon icon="icon-custom" slot="media" style={{ backgroundColor: this.state.selectedPoI ? this.state.selectedPoI.color : "#ffff00" }} />
                                                    </ListInput>
                                                    <li className="poi-option sheet-close"
                                                        style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <span className="color-red" onClick={this.deletePoI}>Delete</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Sheet>
                                <a id="open-sheet" className="link sheet-open" data-sheet=".my-sheet-swipe-to-close" href="#" style={{ display: "none" }}>Open</a>
                            </div>
                            <div id="tab-mask" className="tab">
                                <div className="mask-container">
                                    <div className="image-container">
                                        <img id="canvas-image" src={this.props.image} alt="image not found"
                                            style={{
                                                position: "absolute",
                                                maxWidth: window.innerWidth / 1.1,
                                                maxHeight: window.innerHeight / 1.5,
                                            }} />
                                        <CanvasDraw
                                            ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                                            lazyRadius={0}
                                            brushRadius={10}
                                            hideGrid={true}
                                            hideInterface={true}
                                            brushColor="#000"
                                            canvasWidth={document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().width : window.innerWidth / 1.1}
                                            canvasHeight={document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().height : window.innerHeight / 1.5}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Block>
                <div id="mask-buttons" className="toolbar toolbar-bottom" style={{ background: "#fff", display: "none", position: "absolute", bottom: 0, fontSize: "1.2rem" }}>
                    <div className="toolbar-inner" style={{ background: "#fff" }}>
                        <a className="link">
                            <div className="color-red" onClick={() => { this.saveableCanvas.clear() }}>Clear</div>
                        </a>
                        <a className="link">
                            <div className="color-orange" onClick={() => { this.saveableCanvas.undo(); this.drawLayer(); }}>Undo</div>
                        </a>
                        <a className="link">
                            <div className="color-green" onClick={() => { this.saveMask() }}>Save</div>
                        </a>
                    </div>
                </div>
            </Page >
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CREDITS);

        PkCmsDiscover.getModelViewKeyPoints(this.props.view.uniqueId)
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    this.setState({ poi: res.data });
                }
            });

        PkCmsDiscover.getKeypointContentsData(this.props.model.model.contentId)
            .then((res) => {
                if (res.data && res.data.items.length > 0) {
                    this.setState({ popoverOptions: res.data.items });
                }
            });
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverViewDetail);