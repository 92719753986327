import React, { Fragment } from 'react';
import { NavLeft, Link, NavTitle, NavRight } from 'framework7-react';
import localeStrings from './pk-navbar-local';

const PikkartNavbar = (props) => {

    const getBackButton = () => {
        if (props.showArrowAsNavRight) {
            return (<Link back iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return (
                <img slot="media" src={props.logo}
                    className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }

    const getChangeCustomerButton = () => {
        if (props.userCustomers && props.userCustomers.length > 0) {
            const ret = props.userCustomers.map((item, index) => {
                return <option value={item.Code}>{item.Name}</option>
            });

            if (props.showChangeCustomerButton) {
                return (
                    <NavRight>
                        <Link
                            style={{ color: 'white' }}
                            iconIos="material:filter_list_alt"
                            iconAurora="material:filter_list_alt"
                            iconMd="material:filter_list_alt"
                            smartSelect
                            smartSelectParams={{
                                openIn: 'popup',
                                searchbar: true,
                                searchbarPlaceholder: localeStrings.search_placeholder_text,
                                popupCloseLinkText: '&#10006;',
                                popupTabletFullscreen: true,
                                closeOnSelect: true,
                                scrollToSelectedItem: true,
                                on: {
                                    closed: props.changeCustomerButtonClicked
                                }
                            }} >
                            <select defaultValue={props.defaultCustomer.Code}>
                                {ret}
                            </select>
                        </Link>
                    </NavRight>
                )
            }

        }
    }

    //logoutButtonClicked
    const getLogoutButton = () => {
        if (props.showLogoutButton) {
            return (
                <NavRight>
                    <Link
                        onClick={props.logoutButtonClicked}
                        style={{ color: 'white' }}
                        iconIos="f7:person_crop_circle_badge_xmark"
                        iconAurora="f7:person_crop_circle_badge_xmark"
                        iconMd="f7:person_crop_circle_badge_xmark" />
                </NavRight>
            )
        } else return null;
    }

    return (
        <Fragment>
            <NavLeft>
                {/* <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link> */}


            </NavLeft>
            {props.showLogoutButton ?
                <NavLeft>
                    <img slot="media" src='./img/spacing_icon.png'
                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
                </NavLeft> : null}

            {/* {props.showChangeCustomerButton ?
                <NavLeft>
                    <img slot="media" src='./img/spacing_icon.png'
                        className="icon material-icons" style={{ fontSize: 'xx-large', paddingRight: '10px' }} alt='app_icon' />
                </NavLeft> : null} */}
            <NavTitle>{props.title}</NavTitle>
            {getChangeCustomerButton()}
            {getLogoutButton()}
            <NavRight>
                {JSON.parse(props.showNavRight) ? getBackButton() : null
                    // <img slot="media" src='./img/spacing_icon.png'
                    //     className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
                }
            </NavRight>
        </Fragment>
    )
}
export default PikkartNavbar;