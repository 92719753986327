import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, Link } from 'framework7-react';
import { PkCmsContents } from 'pikkart-cms';
import ContentListItem from './content-list-item';
import Commons from '../commons'
import appLocaleStrings from '../app-local'
import PikkartNavbar from '../components/pk-navbar/pk-navbar';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../analytics-register';

class ContentList extends React.Component {

    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            errorMessage: null,
            infoContentsData: [],
            allowInfinite: true,
            showPreloader: true,
        };
    }

    render() {

        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        return (
            <Page
                infinite
                infinitePreloader={this.state.showPreloader}
                onInfinite={() => this.fetchInfoContents()}
            >
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        sendToBackPage={menuItem.catIndex > 1} />
                </Navbar>

                <div>
                    {this.getInfoContentsData()}
                </div>

            </Page>
        )
    }

    getBackButton = (item) => {
        if (item.catIndex > 1) {
            return (<Link back iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return (
                <img slot="media" src='./img/logo/logo.png'
                    className="icon material-icons" style={{ fontSize: 'xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }


    getInfoContentsData = () => {

        if (this.state.infoContentsData === null ||
            this.state.infoContentsData.length === 0) {
            if (this.state.errorMessage) {
                return (

                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const ret = this.state.infoContentsData.map((item, index) => {
            return (<ContentListItem
                contentData={item}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onCardClick(item);
                }}></ContentListItem>);

        });


        return (
            <Block style={{ padding: '0' }}>

                <div className="demo-expandable-cards">{ret}</div>
                {/*<PageContent style={{ padding: '0' }}>{ret}</PageContent>*/}
            </Block>)
    }

    onCardClick = (item) => {
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_DETAIL, item)
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }

    componentDidMount = () => {
        //TODO: una volta fatto il componente separato i dati gli vengono passati indipendentemente dal fatto che siano in locale o da scaricare
        if (this.props.menuItem.code === '/preferiti') {
            const jsonSringFavoriteContents = localStorage.getItem('favorite_contents');
            if (jsonSringFavoriteContents) {
                const favoritesContents = JSON.parse(jsonSringFavoriteContents);
                if (favoritesContents.length > 0) {
                    this.setState({ infoContentsData: favoritesContents, errorMessage: null, showPreloader: false, allowInfinite: false });
                }
                else {
                    this.noFavoritesContents();
                }
            }
            else {
                this.noFavoritesContents();
            }
        } else if (this.state.infoContentsData === null || this.state.infoContentsData.length === 0) {
            this.fetchInfoContents();
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.menuItem.code === '/preferiti') {
            if (this.props.favoriteContents && this.props.favoriteContents !== prevProps.favoriteContents) {
                if (this.props.favoriteContents.length > 0) {
                    this.setState({ infoContentsData: this.props.favoriteContents, errorMessage: null, showPreloader: false, allowInfinite: false });
                }
                else {
                    this.noFavoritesContents();
                }
            }
        }
    }

    noFavoritesContents = () => {
        this.setState({
            showPreloader: false,
            allowInfinite: false,
            errorMessage: appLocaleStrings.no_contents,
            infoContentsData: this.props.favoriteContents,
            // infoContentsData: []
        });
    }

    fetchInfoContents = () => {
        if (!this.state.allowInfinite) return;
        this.setState({ allowInfinite: false });

        this.page += 1;

        const errorFn = (errorMsg) => {
            if (this.page !== 1) {
                this.setState({ showPreloader: false });
                return;
            }
            this.setState({
                errorMessage: errorMsg,
                // infoContentsData: []
            });

            this.setState({ showPreloader: false });

            this.setState({
                allowInfinite: true,
            });
        }

        const successFn = (infoContentsData) => {
            this.setState({ infoContentsData: this.state.infoContentsData.concat(infoContentsData), errorMessage: null });

            this.setState({ showPreloader: false });

            if (infoContentsData.length === this.pageSize)
                this.setState({
                    allowInfinite: true,
                });
        }

        PkCmsContents.getInfoContentsDataPage({ c1: this.props.c1, c2: this.props.c2, c3: this.props.c3, page: this.page, pageSize: this.pageSize })
            .then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0) {
                        if (this.state.infoContentsData.length > 0) {
                            this.setState({ showPreloader: false });
                            return;
                        }
                        errorFn(appLocaleStrings.no_contents);
                    }
                    else
                        successFn(response.data.items);
                } else {
                    errorFn(response.result.message);
                }
            })
            .catch((errorMessage) => {
                errorFn(errorMessage);
            });
    };
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        favoriteContents: state.app.favoriteContents
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);