import React from 'react';
import { Link, CardHeader } from 'framework7-react';

const ContentHeader = (props) => {
    let closeButton = null;
    if (props.showCloseButton) {
        closeButton = <Link
            cardClose
            className="card-opened-fade-in"
            style={{ position: 'absolute', right: '15px', top: '15px' }}
            iconF7="xmark_circle_fill" />


        if (props.closeButtonFn) {
            closeButton = <Link
                onClick={() => props.closeButtonFn()}
                className="card-opened-fade-in"
                style={{ position: 'absolute', right: '15px', top: '15px' }}
                iconF7="xmark_circle_fill" />
        }
    }

    const imageUrl = (props.content.imageUrl && props.content.imageUrl !== "") ? props.content.imageUrl : 'https://picsum.photos/400';
    return (
        <div style={{
            background: 'url(' + imageUrl + ') no-repeat center top',
            backgroundSize: 'cover',
            height: '400px',
        }
        }>
            <CardHeader
                style={{
                    fontSize: 'var(--f7-card-expandable-header-font-size)',
                    fontFamily: 'var(--f7-font-family)',
                    fontWeight: 'var(--f7-card-expandable-header-font-weight)'
                }}>
                {props.content.title}
            </CardHeader>

            { closeButton}
        </div >)
}

export default ContentHeader;