import React from 'react';
import { PkCmsDiscover, PkCmsContents } from 'pikkart-cms';
import Commons from '../../commons'
import { f7, Page, PageContent } from 'framework7-react';
import store from '../../redux/store'
import { PkCordova, PkCordovaUi, PkCordovaAuthentication, PkCordovaNotifications } from 'pikkart-cordova';
import { PkCms, PkCmsNotifications, PLATFORM_TYPE } from 'pikkart-cms';
import { Ra, RaLog, LOG_TYPE, RaCrypto } from 'pikkart-remote-assistance';
import { initializedFirebaseApp } from "../../init-fcm";

import { setReceivedNotifications } from '../../redux/actions';

import { connect } from 'react-redux';

import MobileDetect from 'mobile-detect';

class Landing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (<Page><PageContent><div></div></PageContent></Page>);
  }

  componentDidMount = () => {
    console.log('landing componentdidmount');

    let self = this;
    this.initRa();
    if (!PkCordova.isCordova()) {
        self.initCms('123456789', 'browserweb');
    }
    else {
      self.onDeviceReady((deviceId, platform) => {
        self.initCms(deviceId, platform);
      });
    }
/*
    if (!PkCordova.isCordova()) {
      this.loadMenu();
    }*/
  }

  initCms = (deviceId, platform) => {
      
    switch (platform) {
      case 'android':
        platform = PLATFORM_TYPE.ANDROID;
        break;
      case 'ios':
        platform = PLATFORM_TYPE.IOS;
        break;
      default:
        platform = PLATFORM_TYPE.WEB;
        break;
    }

    const deviceConfig = {
      apiUrl: process.env.REACT_APP_CMS_API_URL,
      apiKey: process.env.REACT_APP_CMS_API_KEY,
      deviceId: deviceId? deviceId : 'browserweb',
      platformType: platform
    };

    PkCms.init(deviceConfig);

    
    this.goToSplash();
  }

  goToSplash = () => {
    setTimeout(() => {
      let path = (this.props.returnPath) ? this.props.returnPath : '/splash/';
      this.props.f7router.navigate(path, { reloadAll: true });
    }, 300);
  }

  loadMenu = () => {
    console.log('loadMenu');
    const contentSuccess = (content) => {
      this.props.f7router.navigate(Commons.getRequestedContentDetailUrl(content.data.content), { props: { contentItem: content.data } });
    }

    RaLog.log("f7route " + JSON.stringify(this.props.f7route));
    RaLog.log("f7route.query " + JSON.stringify(this.props.f7route.query));
    RaLog.log("f7route.query.keypointid " + JSON.stringify(this.props.f7route.query.keypointid));

    if (this.props.f7route && this.props.f7route.query) {
      if (this.props.f7route.query.keypointid) {
        setTimeout(() => {
          PkCmsDiscover.getKeypointContentData(this.props.f7route.query.keypointid)
            .then((response) => {
              contentSuccess(response);
              // this.props.f7router.navigate(Commons.getContentDetailUrl(response.data.content), { props: {contentItem: response.data }});
            })
            .catch((error) => {
              console.error(error);
              this.props.f7router.navigate(process.env.REACT_APP_DEFAULT_CODE_PAGE, { reloadAll: true });
            });
        }, 300);
      } else if (this.props.f7route.query.contentid) {
        setTimeout(() => {
          PkCmsContents.getInfoContentData(this.props.f7route.query.contentid)
            .then((response) => {
              contentSuccess(response);
              // this.props.f7router.navigate(Commons.getContentDetailUrl(response.data.content), { props: {contentItem: response.data }});
            })
            .catch((error) => {
              console.error(error);
              this.props.f7router.navigate(process.env.REACT_APP_DEFAULT_CODE_PAGE, { reloadAll: true });
            });
        }, 300);
      }
      else {
        this.goToSplash();
      }

    } else {
      this.goToSplash();
    }
  }

  initCordovaOnDevice = () => {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  }

  initRa = () => {
    RaLog.log('INIT');
    console.log('api url', process.env.REACT_APP_CMS_API_URL)
    Ra.init({
      appCode: process.env.REACT_APP_CODE,
      customerCode: process.env.REACT_APP_CUSTOMER_CODE,
      apiUrl: process.env.REACT_APP_CMS_API_URL,
      cryptoSchema: RaCrypto.CRYPTOSCHEMA_JSON_V1,
      //firebaseKey: process.env.REACT_APP_FIREBASE_WEB_KEY,
      //openCvUrl: '/js/opencv.js',
      //initializedFirebaseApp: initializedFirebaseApp,

      /*webRtcAdapterUrl: './js/webrtc-adapter.js',*/
      logType: LOG_TYPE.VERBOSE,
    })
  }

  onDeviceReady = (callback) => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.tablet()) {
      PkCordovaUi.lockOrientationInLandscape();
    }
    else if (md.phone()) {
      PkCordovaUi.lockOrientationInPortrait();
    }
    //store.dispatch(RaReduxActions.setCordovaReady(true));
    PkCordova.init_cordova();

    if (PkCordovaAuthentication.getDeviceId().then((deviceId) => {

      var platform = PkCordova.getPlatform();

      if (callback)
        callback(deviceId, platform);
      //PkCmsNotifications.initCordovaNotifications(onMessageReceived);
    }));

    const onMessageReceived = (message) => {
      RaLog.log("onMessageReceived", message);
      PkCordovaNotifications.getReceivedMessages().then(function (messages) {
        store.dispatch(setReceivedNotifications(messages));
      });
    }

    PkCordovaNotifications.getReceivedMessages().then(function (messages) {
      store.dispatch(setReceivedNotifications(messages));
    });

    this.processingBackbuttonRequest = false;
    const self = this;
    document.addEventListener("backbutton", () => {

      const resetProcessingBackbuttonRequest = () => {
        setTimeout(() => {
          self.processingBackbuttonRequest = false;
        }, 100);
      }
      

      RaLog.log('backButton', 'back button pressed');

      //gestisco un semaforo perché questo componente è utilizzato da tutte le pagine, quindi questo evento viene scatenato n volte contemporaneamente
      //non posso fare diversamente perché l'oggetto router è accessibile solo dalle pagine dichiarate nel router, e questo mi impedisce di fare redirect in AppBoot, Landing o altre pagine centralizzate
      if (self.processingBackbuttonRequest === false) {
        self.processingBackbuttonRequest = true;

        if (f7.panel.get().opened) {
          resetProcessingBackbuttonRequest();
          f7.panel.close();
        }
        else if (f7.sheet.get()) {
          resetProcessingBackbuttonRequest();
          f7.sheet.get().close();
        }
        else if (f7.popup.get()) {
          resetProcessingBackbuttonRequest();
          f7.popup.get().close();
        }
        else if (document.getElementsByClassName("card-opened").length > 0) {
          resetProcessingBackbuttonRequest();
          f7.card.close();
        }
        else if (f7.views.main.router.url.includes(process.env.REACT_APP_DEFAULT_CODE_PAGE)) {
          resetProcessingBackbuttonRequest();
          navigator.app.exitApp();
        } else {
          resetProcessingBackbuttonRequest();
          self.props.f7router.back();
        }
        /*
               else if (!this.props.currentRoute.url.includes(simpleDefaultRoute)) {
                 this.props.back();
                 resetProcessingBackbuttonRequest();
               }
               else {
                 navigator.app.exitApp();
                 resetProcessingBackbuttonRequest();
               }*/
      }

    }, false);


    //this.loadMenu();
  }
};

// #region Redux
const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Landing);