import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDByAAG4ll8sNMiR61OfDIYTkE6TKip5bo",
    authDomain: "igv-remote-assistance.firebaseapp.com",
    databaseURL: "https://igv-remote-assistance.firebaseio.com",
    projectId: "igv-remote-assistance",
    storageBucket: "igv-remote-assistance.appspot.com",
    messagingSenderId: "775624306890",
    appId: "1:775624306890:web:655ff72cde9a9a33f6c433",
    measurementId: "G-YE7FJEPVTB"
  });

export { initializedFirebaseApp };