import React from 'react';
import { connect } from 'react-redux';
import { PkCmsContents, CONTENT_VIEW } from 'pikkart-cms';
import { Page, Navbar, NavRight, Link, PageContent, Toolbar, Tabs, Tab, Block, BlockTitle, Icon, List, Card, CardContent, ListItem, Swiper, SwiperSlide, Button, Popup, View } from 'framework7-react';
import { PkCordovaARSdk, PkCordovaPermissions, PkCordovaSharing, PkCordova } from 'pikkart-cordova';
import ContentListItem from './content-list-item';
import Commons from '../commons';
import appLocaleStrings from '../app-local';
import localeStrings from './content-detail-local';
import * as actions from '../redux/actions';
import { AnalyticsRegister, ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../analytics-register'
import PikkartNavbar from '../components/pk-navbar/pk-navbar';
import { RaLog } from 'pikkart-remote-assistance';
import TutorialHelper from '../helpers/tutorial-helper';
import ReactHtmlParser from 'react-html-parser';

class ContentDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            correlateInfoContentsData: null,
            isFavoriteContent: false,
            starIcon: 'star'
        };
    }


    render() {
        RaLog.log('Open Content: ' + this.$f7route.params.contentId);
        const getContentViewLayout = (item) => {

            if (item) {
                switch (item.content.ctnView) {
                    case CONTENT_VIEW.DISCOVER_MODEL:
                        return this.getDiscoverModelView(item.content);
                    case CONTENT_VIEW.INFOCONTENT:
                        return this.getInfoContenView(item.content);
                    case CONTENT_VIEW.MARKERLESS:
                        return this.getInfoContenView(item.content);
                    case CONTENT_VIEW.DISCOVER_KEYPOINT:
                        return this.getKeypointContentView(item.content);
                    case CONTENT_VIEW.PLACE:
                        return this.getPlaceView(item.content);
                    case CONTENT_VIEW.ITINERARY:
                        return this.getItineraryView(item.content);

                    default:
                        return (
                            <Card>
                                <CardContent style={{ textAlign: 'center' }}>
                                    error retreiving content
                            </CardContent>
                            </Card>
                        );
                }
            }
        }


        const getContentViewToolbar = (item) => {
            if (item) {

                switch (item.content.ctnView) {
                    case CONTENT_VIEW.DISCOVER_MODEL:
                        return this.getDiscoverModelViewToolbar(item.content);
                    case CONTENT_VIEW.INFOCONTENT:
                        return this.getInfoContentViewToolbar(item.content);
                    case CONTENT_VIEW.DISCOVER_KEYPOINT:
                        return this.getKeypointContentViewToolbar(item.content);
                    case CONTENT_VIEW.PLACE:
                        return this.getPlaceViewToolbar(item.content);
                    case CONTENT_VIEW.ITINERARY:
                        return this.getItineraryViewToolbar(item.content);

                    default:
                        return (null);
                }
            }
        }

        const discoverPopup = (
            <Popup push className="popup-tutorial-ar-discover" closeOnEscape={true}
                tabletFullscreen={true}>
                <View>
                    <Page>
                        <Navbar transparent={true}>
                            <NavRight>
                                <Link popupClose iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                            </NavRight>
                        </Navbar>

                        {TutorialHelper.getDiscoverContentDetailTutorial(this.toggleClicked, () => this.startDiscoverExperience(this.props.contentItem.content.extlId))}                    </Page>
                </View>
            </Popup>
        )

        let navBar = null;
        if (this.props.contentItem) {
            navBar = (

                <Navbar>
                    <PikkartNavbar
                        title={this.props.contentItem.content.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={true} />
                </Navbar>);
        }

        return (

            <Page>
                {navBar}

                {getContentViewToolbar(this.props.contentItem)}

                {getContentViewLayout(this.props.contentItem)}

                {discoverPopup}
            </Page>
        )
    }

    getDiscoverModelViewToolbar = (content) => {
        return (null);
    }

    getDiscoverModelView = (content) => {


        let discoverButton = <Button style={{ marginTop: '20px' }} className="col button button-raised button-fill color-red mediateca popup-open" popupOpen=".popup-tutorial-ar-discover">Avvia la visita interattiva</Button>
        if (this.props.skippedDiscoverTutorial) {
            discoverButton = <Button style={{ marginTop: '20px' }} className="col button button-raised button-fill color-red mediateca popup-open" onClick={(e) => { this.startDiscoverExperience(this.props.contentItem.content.extlId) }}>Avvia la visita interattiva</Button>//<Button style={{ marginTop: '20px' }} raised fill className="col button color-red mediateca" onClick={(e) => { this.startMarkerRecognition() }}>Inquadra un AR Logo</Button>
        }

        const shareLink = (this.props.contentItem.content.googleUrl && this.props.contentItem.content.googleUrl !== "" ?
            <Link className='material-icons' iconIos="material:share" iconMd="material:share" color='black' onClick={() => { this.shareContent(this.props.contentItem.content); }} /> :
            "");

        return (
            <Block>
                <BlockTitle large style={{ textAlign: 'center' }}>{content.title}</BlockTitle>
                <div className="data" style={{ textAlign: 'center' }}>
                    {content.date}
                </div>
                <div className="video-container" style={{ marginTop: '0' }} >
                    {!content.videoUrl ? <img src={content.imageUrl} style={{ width: '100%' }} alt='content' /> : this.getVideoPlayer(content)}

                    <div style={{ textAlign: 'right' }}>

                        <Link iconMd={"f7:" + this.state.starIcon} iconIos={"f7:" + this.state.starIcon} color='black' onClick={() => { this.saveToFavoritesContents(this.props.contentItem, !this.state.isFavoriteContent) }} />
                        {shareLink}
                        {discoverButton}
                    </div>
                </div>

                <div>
                    <p>{ReactHtmlParser(content.descr.replaceAll("\n", "<br>"))}</p>

                    {this.getContactList(content)}

                </div>

                {this.getImageGallery(content)}
            </Block>
        )
    }

    getKeypointContentViewToolbar = (content) => {

        return (null);
    }

    getKeypointContentView = (content) => {

        return (

            <Block className="page-content">
                {this.getInfoContentViewTab(content)}
            </Block>

        );
    }

    getInfoContentViewToolbar = (content) => {

        return (
            <Toolbar inner tabbar={true} top={true}>
                <Link tabLink={"#luogo-descrizione_" + content.id} tabLinkActive text={localeStrings.info} tabbarLabel={true}></Link>
                <Link tabLink={"#luogo-mappa_" + content.id} text={localeStrings.map} tabbarLabel={true} />
                <Link tabLink={"#luogo-dintorni_" + content.id} text={localeStrings.near} tabbarLabel={true}></Link>
            </Toolbar>);
    }

    getInfoContenView = (content) => {

        return (

            <Tabs>
                <Tab id={"luogo-descrizione_" + content.id} style={{ paddingTop: '25px' }} className="page-content" tabActive>

                    {this.getInfoContentViewTab(content)}

                </Tab>
                <Tab id={"luogo-mappa_" + content.id} style={{ paddingTop: '25px' }} className="page-content">
                    <Block>
                        {this.getMapServiceTab(content)}
                    </Block>
                </Tab>
                <Tab id={"luogo-dintorni_" + content.id} style={{ paddingTop: '25px' }} className="page-content">
                    <Block>
                        {this.getRelatedContentsTab(content)}
                    </Block>
                </Tab>
            </Tabs>

        );
    }

    getInfoContentViewTab = (content) => {


        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link className='material-icons' iconIos="material:share" iconMd="material:share" color='black' onClick={() => { this.shareContent(this.props.contentItem.content); }} /> :
            "");

        return (
            <Block style={{ marginTop: 0 }}>
                <BlockTitle large style={{ textAlign: 'center', marginTop: 0 }}>{content.title}</BlockTitle>
                <div className="data" style={{ textAlign: 'center' }}>
                    {content.date}
                </div>
                <div className="video-container">
                    <img src={content.imageUrl} style={{ width: '100%' }} alt='content' />

                    <div style={{ textAlign: 'right' }}>

                        <Link iconMd={"f7:" + this.state.starIcon} iconIos={"f7:" + this.state.starIcon} color='black' onClick={() => { this.saveToFavoritesContents(this.props.contentItem, !this.state.isFavoriteContent) }} />
                        {shareLink}
                    </div>
                </div>

                <div>
                    <p>{ReactHtmlParser(content.descr.replaceAll("\n", "<br>"))}</p>

                    {this.getContactList(content)}

                </div>

                {this.getImageGallery(content)}
            </Block>
        )
    }

    getMapId = (content) => {
        return 'map_' + content.id;
    }

    getRelatedContentsTab = (content) => {

        if (!this.state.correlateInfoContentsData
            || !this.state.correlateInfoContentsData.items
            || this.state.correlateInfoContentsData.items.length === 0) {
            if (this.state.errorMessage) {
                return (
                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const ret = this.state.correlateInfoContentsData.items.map((item, index) => {

            return (<ContentListItem
                contentData={item}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onCardClick(item);
                }}></ContentListItem>);
        });

        return (
            <Block style={{ padding: '0' }}>
                <PageContent style={{ padding: '0' }}>{ret}</PageContent>
            </Block>);
    }

    getMapServiceTab = (content) => {

        return (<div id={this.getMapId(content)} style={{ height: '500px' }}></div>);
    }

    getPlaceViewToolbar = () => {

        return (
            <Toolbar inner tabbar={true} top={true}>
                <Link tabLink="#luogo-descrizione" tabLinkActive text={localeStrings.info} tabbarLabel={true}></Link>
                <Link tabLink="#luogo-mappa" text={localeStrings.map} tabbarLabel={true} />
                <Link tabLink="#luogo-dintorni" text={localeStrings.near} tabbarLabel={true}></Link>
            </Toolbar>);
    }

    getPlaceView = (content) => {

        return (

            <Tabs>
                <Tab id="luogo-descrizione" style={{ paddingTop: '25px' }} className="page-content" tabActive>

                    {this.getInfoContentViewTab(content)}

                </Tab>
                <Tab id="luogo-mappa" style={{ paddingTop: '25px' }} className="page-content">
                    <Block>
                        {this.getMapServiceTab(content)}
                    </Block>
                </Tab>
                <Tab id="luogo-dintorni" style={{ paddingTop: '25px' }} className="page-content">
                    <Block>
                        {this.getRelatedContentsTab(content)}
                    </Block>
                </Tab>
            </Tabs>

        );
    }

    getItineraryViewToolbar = (content) => {

        return (
            <Toolbar inner tabbar={true} top={true}>
                <Link tabLink="#servizio-descrizione" tabLinkActive text={localeStrings.info} tabbarLabel={true}></Link>
                <Link tabLink="#servizio-correlato" text={localeStrings.places} tabbarLabel={true}></Link>
                <Link tabLink="#servizio-mappa" text={localeStrings.map} tabbarLabel={true} />
            </Toolbar>);
    }

    getItineraryView = (content) => {

        return (

            <Tabs>
                <Tab id="servizio-descrizione" style={{ paddingTop: '25px' }} className="page-content" tabActive>
                    {this.getInfoContentViewTab(content)}
                </Tab>
                <Tab id="servizio-correlato" style={{ paddingTop: '25px' }} className="page-content">
                    <Block>
                        {this.getRelatedContentsTab(content)}
                    </Block>
                </Tab>
                <Tab id="servizio-mappa" style={{ paddingTop: '25px' }} className="page-content">
                    <Block>
                        {this.getMapServiceTab(content)}
                    </Block>
                </Tab>
            </Tabs>

        )
    }

    //add: if true add to favorites if false remove
    saveToFavoritesContents = (favoriteContent, add) => {
        if (add) {
            this.registerAnalyticsEvent(ANALYTICS_EVENT.ADD_TO_FAVORITES, favoriteContent);


            let favoriteContentsArray = [];
            favoriteContentsArray.push(favoriteContent);

            const jsonStringFavoriteContents = localStorage.getItem('favorite_contents');
            if (jsonStringFavoriteContents) {
                const favoritesContents = JSON.parse(jsonStringFavoriteContents);
                favoriteContentsArray.push.apply(favoriteContentsArray, favoritesContents);
            }

            localStorage.setItem('favorite_contents', JSON.stringify(favoriteContentsArray));
            this.props.setFavoriteContents(favoriteContentsArray);

            this.setState({ isFavoriteContent: add, starIcon: 'star_fill' });
        }
        else {
            const jsonStringFavoriteContents = localStorage.getItem('favorite_contents');
            if (jsonStringFavoriteContents) {
                const favoritesContents = JSON.parse(jsonStringFavoriteContents);
                favoritesContents.forEach((content, index) => {
                    if (favoriteContent.content.id === content.content.id) {
                        favoritesContents.splice(index, 1);
                    }
                });

                localStorage.setItem('favorite_contents', JSON.stringify(favoritesContents));
                this.props.setFavoriteContents(favoritesContents);
            }
            this.setState({ isFavoriteContent: add, starIcon: 'star' });
        }
    }

    shareContent = (content) => {
        if (PkCordova.isCordova()) {
            PkCordovaSharing.share(null, null, null, content.googleUrl);
        }
    }

    getVideoPlayer = (content) => {
        return (
            <div>
                <video className="video-fullscreen" controls>
                    <source src={content.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="titolo-video">{content.title}</div>
            </div>)
    }

    getImageGallery = (content) => {

        if (content.imageUrls.length === 0)
            return null;


        const ret = content.imageUrls.map((imageUrl, index) => {
            return (<SwiperSlide key={'image-' + index} style={{ backgroundImage: "url(" + imageUrl + ")" }}></SwiperSlide>)
        });

        return (
            <div>
                <BlockTitle medium>
                    Galleria
                </BlockTitle>
                <Block>
                    <Swiper className="galleria" pagination params={{
                        spaceBetween: 10,
                        slidesPerView: 3,
                    }}>
                        {ret}
                    </Swiper>
                </Block>
            </div>)
    }

    getContactList = (content) => {
        const contactItems = [];

        if (content.webUrl) {
            contactItems.push(
                <ListItem key='webUrl'
                    header='Sito web:'
                    title={content.webUrl}
                    onClick={() => { window.open(encodeURI(content.webUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:globe" aurora="f7:globe" md="material:language" />
                </ListItem>);
        }

        if (content.phone) {
            contactItems.push(
                <ListItem key='phone'
                    header='Telefono:'
                    title={content.phone}
                    onClick={() => { window.open(encodeURI("tel:" + content.phone), '_system') }}>
                    <Icon slot="media" ios="f7:phone" aurora="f7:phone" md="material:local_phone" />
                </ListItem>);
        }

        if (content.email) {
            contactItems.push(
                <ListItem key='email'
                    header='Email:'
                    title={content.email}
                    onClick={() => { window.open(encodeURI("mailto:" + content.email), '_system') }}>
                    <Icon slot="media" ios="f7:envelope" aurora="f7:envelope" md="material:mail" />
                </ListItem>);
        }

        if (content.fax) {
            contactItems.push(
                <ListItem key='fax'
                    header='Telefono:'
                    title={content.fax}
                    onClick={() => { window.open(encodeURI("tel:" + content.fax), '_system') }}>
                    <Icon slot="media" ios="f7:printer" aurora="f7:printer" md="material:print" />
                </ListItem>);
        }
        //MANCA ECOMMERCE
        if (content.youtubeUrl) {
            contactItems.push(
                <ListItem key='youtube'
                    header='Youtube:'
                    title={content.youtubeName ? content.youtubeName : ''}
                    onClick={() => { window.open(encodeURI(content.youtubeUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:play_rectangle" aurora="f7:play_rectangle" md="material:slideshow" />
                </ListItem>);
        }

        if (content.attchmUrl) {
            contactItems.push(
                <ListItem key='attchm'
                    header='PDF:'
                    title={content.attchmName ? content.attchmName : ''} after={content.attchmDescr}
                    onClick={() => { window.open(encodeURI(content.attchmUrl), '_system') }} >
                    <Icon slot="media" ios="f7:arrow_down_doc" aurora="f7:arrow_down_doc" md="material:attach_file" />
                </ListItem>);
        }

        if (content.skype) {
            contactItems.push(
                <ListItem key='skype'
                    header='Skype:'
                    title={content.skype}
                    onClick={() => { window.open(encodeURI("skype:" + content.skype + "?add"), '_blank') }}>
                    {/*<Icon slot="media" ios="f7:play_rectangle" aurora="f7:play_rectangle" md="material:slideshow" />*/}
                </ListItem>);
        }

        if (content.fbUrl) {
            contactItems.push(
                <ListItem key='fb'
                    header='Facebook:'
                    title={content.fbUrl}
                    onClick={() => { window.open(encodeURI(content.fbUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:logo_facebook" aurora="f7:logo_facebook" md="material:facebook" />
                </ListItem>);
        }

        if (content.instUrl) {
            contactItems.push(
                <ListItem key='inst'
                    header='Instagram:'
                    title={content.instUrl}
                    onClick={() => { window.open(encodeURI(content.instUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:logo_instagram" aurora="f7:logo_instagram" md="f7:logo_instagram" />
                </ListItem>);
        }

        if (content.linkedinUrl) {
            contactItems.push(
                <ListItem key='linkedin'
                    header='Linkedin:'
                    title={content.linkedinUrl}
                    onClick={() => { window.open(encodeURI(content.linkedinUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:logo_linkedin" aurora="f7:logo_linkedin" md="f7:logo_linkedin" />
                </ListItem>);
        }

        if (content.twUrl) {
            contactItems.push(
                <ListItem key='tw'
                    header='Twitter:'
                    title={content.twUrl}
                    onClick={() => { window.open(encodeURI(content.twUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:logo_twitter" aurora="f7:logo_twitter" md="f7:logo_twitter" />
                </ListItem>);
        }

        if (content.googleUrl) {
            contactItems.push(
                <ListItem key='google'
                    header='Google:'
                    title={content.googleUrl}
                    onClick={() => { window.open(encodeURI(content.googleUrl), '_blank') }}>
                    <Icon slot="media" ios="f7:logo_google" aurora="f7:logo_google" md="f7:logo_google" />
                </ListItem>);
        }

        if (content.pinUrl) {
            contactItems.push(
                <ListItem key='pin'
                    header='Pinterest:'
                    title={content.pinUrl}
                    onClick={() => { window.open(encodeURI(content.pinUrl), '_blank') }}>
                    {/*<Icon slot="media" ios="f7:play_rectangle" aurora="f7:play_rectangle" md="material:slideshow" />*/}
                </ListItem>);
        }

        if (contactItems.length === 0)
            return null;

        return (
            <List className="lista-contatti">
                {contactItems}
            </List>)
    }

    onCardClick = (item) => {
        this.$f7router.navigate(Commons.getContentDetailUrl(item.content), { props: { contentItem: item } });
    }

    createGoogleMap = (self, content) => {

        return new window.google.maps.Map(document.getElementById(self.getMapId(content)), {
            center: { lat: content.lat, lng: content.long },
            zoom: 14
        });
    }

    addContentMarkerToMap = (self, map, bounds, infowindow, relatedContent) => {
        let position = { lat: relatedContent.lat, lng: relatedContent.long };

        RaLog.log('Add marker to map', position);
        var marker = new window.google.maps.Marker({ position: position, map: map });

        //extend the bounds to include each marker's position
        bounds.extend(marker.position);

        window.google.maps.event.addListener(marker, 'click', (function (marker, relatedContent) {
            return function () {
                infowindow.setContent(relatedContent.title);
                infowindow.open(map, marker);
            }
        })(marker, relatedContent));

        //now fit the map to the newly inclusive bounds
        map.fitBounds(bounds);

        //(optional) restore the zoom level after the map is done scaling
        var listener = window.google.maps.event.addListener(map, "idle", function () {
            map.setZoom(14);
            window.google.maps.event.removeListener(listener);
        });
    }

    createGoogleMapWithMarkers = (self, content, relatedContents) => {

        let map = self.createGoogleMap(self, content);

        var bounds = new window.google.maps.LatLngBounds();
        var infowindow = new window.google.maps.InfoWindow();

        if (relatedContents && relatedContents.items) {
            relatedContents.items.forEach(relatedContent => {
                self.addContentMarkerToMap(self, map, bounds, infowindow, relatedContent.content);
            });
        }
        else if (content.lat && content.long) {
            self.addContentMarkerToMap(self, map, bounds, infowindow, content);
        }
    }

    componentDidMount = () => {

        const self = this;
        const contentItem = this.props.contentItem
        if (contentItem) {
            AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CONTENT_DETAIL);
            this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_DETAIL, contentItem);

            const content = this.props.contentItem.content;

            if (self.state.correlateInfoContentsData === null
                && content.ctnView === CONTENT_VIEW.ITINERARY) {

                self.fetchRelatedContents(content,
                    CONTENT_VIEW.PLACE,
                    content.id,
                    null)
                    .then((relatedContents) => {
                        self.createGoogleMapWithMarkers(self, content, relatedContents);
                    });
            }
            else if (self.state.correlateInfoContentsData === null
                && (content.ctnView === CONTENT_VIEW.PLACE || content.ctnView === CONTENT_VIEW.INFOCONTENT)) {

                self.fetchRelatedContents(content, CONTENT_VIEW.PLACE, 0, { radius: 3000, location: { lat: content.lat, lng: content.long } })
                    .then((relatedContents) => {
                        self.createGoogleMapWithMarkers(self, content);
                    });
            }

            const skippedDiscoverTutorial = localStorage.getItem('skip_discover_tutorial');
            if (skippedDiscoverTutorial != null)
                this.props.setSkippedDiscoverTutorial(JSON.parse(skippedDiscoverTutorial));
            else
                this.props.setSkippedDiscoverTutorial(false);

            const jsonSringFavoriteContents = localStorage.getItem('favorite_contents');
            if (jsonSringFavoriteContents) {
                const favoritesContents = JSON.parse(jsonSringFavoriteContents);
                favoritesContents.forEach((content) => {
                    if (this.props.contentItem.content.id === content.content.id) {
                        self.setState({
                            isFavoriteContent: true,
                            starIcon: 'star_fill'
                        });
                    }
                });
            }
        }
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }

    closeModalViewClick = () => {
        if (PkCordova.isCordova())
            PkCordovaARSdk.closeModalView();
    }

    fetchRelatedContents = (content, view, relFilter, nearFilters) => {
        return new Promise((resolve, reject) => {
            const errorFn = (errorMsg) => {
                this.setState({
                    errorMessage: errorMsg,
                    correlateInfoContentsData: null
                });
                resolve(null);
            }

            const successFn = (relatedContents) => {
                if (relatedContents && relatedContents.items) {
                    for (let i = 0; i < relatedContents.items.length; i++) {
                        if (relatedContents.items[i].content.id === content.id) {
                            relatedContents.items.splice(i, 1);
                            break;
                        }
                    }
                }
                this.setState({ correlateInfoContentsData: relatedContents });
                resolve(relatedContents);
            }

            let filters = { v: view };
            if (relFilter) {
                filters.rel = relFilter;
            }
            if (nearFilters) {
                filters.nearFilters = nearFilters;
            }

            PkCmsContents.getInfoContentsDataPage(filters).then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0)
                        errorFn(appLocaleStrings.no_contents);
                    else
                        successFn(response.data);
                } else {
                    errorFn(response.result.message);
                }
            })
                .catch((errorMessage) => {
                    errorFn(errorMessage);
                });


        });
    };

    startDiscoverExperience = (modelId) => {
        /*const skippedDiscoverTutorial = sessionStorage.getItem('skip_discover_tutorial');
        if (skippedDiscoverTutorial) {

        }*/


        let _dialog = null;

        const startDiscoverFragment = (modelId) => {
            this.props.setActivityPaused(true);
            PkCordovaARSdk.startDiscover(process.env.REACT_APP_CMS_API_URL, modelId, '?contentid=', "?keypointid=", process.env.REACT_APP_DISCOVER_KEYPOINTS_COLOR);
        }

        const downloadDiscoverModel = (modelId) => {
            PkCordovaPermissions.askStoragePermission().then((status) => {
                openDeterminedProgress();
                PkCordovaARSdk.downloadDiscoverModel(process.env.REACT_APP_CMS_API_URL, modelId, true, finished, error, progress);
            }).catch((err) => {
                RaLog.error('Download discover model', err);
                openErrorDialog(err);
            });

            const finished = () => {
                RaLog.log('download models finished');
                _dialog.setText('Loading models 100 %');

                closeDialog();

                const integerModelId = modelId.substring(modelId.lastIndexOf("_") + 1, modelId.lenght);
                startDiscoverFragment(integerModelId);
            };

            const progress = (progressValue) => {
                RaLog.log('download model progress ' + progressValue);
                if (_dialog)
                    _dialog.setProgress(progressValue);
                _dialog.setText('Loading models ' + Math.round(progressValue) + ' %');
            };

            const error = () => {
                _dialog.setText('download models error');
                closeDialog();
            };

            const openDeterminedProgress = () => {
                const app = this.$f7;
                let progress = 0;
                _dialog = app.dialog.progress('Download', progress);
                _dialog.setText('Loading models');
            }

            const openErrorDialog = (error) => {
                const app = this.$f7;
                _dialog = app.dialog.alert(error);
                _dialog.setTitle('Error');
            }
        }

        const closeDialog = () => {
            setTimeout(function () {
                _dialog.close();
            }, this.state.dialogClosingTime)
        }

        const intModelId = modelId.substring(modelId.lastIndexOf("_") + 1, modelId.lenght);
        downloadDiscoverModel(intModelId);
    }

    toggleClicked = (selected) => {
        this.props.setSkippedDiscoverTutorial(selected);
        localStorage.setItem('skip_discover_tutorial', selected);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        skippedDiscoverTutorial: state.app.skippedDiscoverTutorial,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSkippedDiscoverTutorial: (skippedDiscoverTutorial) => dispatch(actions.setSkippedDiscoverTutorial(skippedDiscoverTutorial)),
        setFavoriteContents: (favoriteContents) => dispatch(actions.setFavoriteContents(favoriteContents)),
        setActivityPaused: (activityPaused) => dispatch(actions.setActivityPaused(activityPaused)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);