import React from 'react';
import { connect } from 'react-redux';
import { Card, CardContent } from 'framework7-react';
import DetailView from './detail-view';
import { RaLog } from 'pikkart-remote-assistance';

class ContentListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            effect: 'blink',
        };
    }

    render() {
        let item = this.props.contentData;

        return (
            <Card onCardOpen={() => this.onCardClick(item)} expandable key={"contentid-" + item.content.id}>
                <CardContent padding={false}>
                    <DetailView item={item} showCloseButton={true} currentCompany={this.props.currentCompany}>

                    </DetailView>
                </CardContent>
            </Card>
        )
    }

    onCardClick = (item) => {
        if (this.props.onCardClickHandler) {
            this.props.onCardClickHandler(item);
        }
    }

    componentDidMount = () => {

    }

    imageLoaded = () => {
        RaLog.log('image loaded');
        this.setState({ loading: false });
    }
};

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentListItem);