import React from 'react';
import { Page, SwiperSlide, Swiper, Toggle, Button } from 'framework7-react';
import MobileDetect from 'mobile-detect';

class PikkartTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        const getImageSize = () => {
            const md = new MobileDetect(window.navigator.userAgent);
            if (md.phone()) {
                return '80%'
            }
            else {
                return '40%'
            }
        }

        const getMargins = () => {
            const md = new MobileDetect(window.navigator.userAgent);
            if (md.phone()) {
                return { margin: '40px' }
            }
            else {
                return { marginLeft: '200px', marginRight: '200px' }
            }
        }

        const swiperSlides = this.props.slides.map((item, index) => {
            return (
                <SwiperSlide key={index}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={item.image} alt={'tutorial-' + index + '-page'} style={{ width: getImageSize() }} />
                    </div>
                    <div style={{ textAlign: 'center', margin: '15px' }}>
                        {item.text}
                    </div>
                </SwiperSlide>
            );
        });

        const getToggle = () => {
            if (this.props.showToggle)
                return (
                    <div style={{ textAlign: 'right' }}>
                        <span>{this.props.toggleText}</span>
                        <Toggle style={{ margin: "10px" }} onToggleChange={(event) => { this.props.toggleClicked(event) }} init></Toggle>
                    </div>
                )
            else
                return null;
        };

        const getButton = () => {
            if (this.props.showButton)
                return (
                    <Button onClick={(e) => { this.props.buttonClicked() }} style={getMargins()} large fill> { this.props.buttonText}</Button >
                )
            else
                return null;
        };

        return (
            <Page>
                <Swiper pagination style={{ height: "75%" }} params={{ observer: true, observeParents: true }}>
                    {swiperSlides}
                </Swiper>

                {getToggle()}

                {getButton()}
            </Page>
        );
    }

};

export default PikkartTutorial;