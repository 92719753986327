import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, List, ListItem, SwipeoutActions, SwipeoutButton, f7 } from 'framework7-react';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register';
import Commons from '../../commons';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import { PkCmsDiscover } from 'pikkart-cms';
import { PkCordova } from 'pikkart-cordova';
import Webcam from 'webcam-easy';
import ImageCard from './image-card';
import placeholder from './placeholder.jpg';

class DiscoverViews extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            views: [],
            infoContents: [],
            images: [],
            layers: [],
            pictureSource: null,
            destinationType: null,
            webcam: null,
            tabViews: true
        };

        this.openCamera = this.openCamera.bind(this);
    }

    /**
     * Funzione che restituisce il base64 dell'immagine selezionata o scattata (Cordova)
     * 
     * @param {string} imageData base64 dell'immagine
     */
    onPhotoDataSuccess(imageData) {
        let imagebase64 = "data:image/png;base64," + imageData;

        let img = document.getElementById("temp_image");
        this.resizeImage(imagebase64).then(resizedImageBase64 => {

            img.src = resizedImageBase64;
        })
    }

    /**
     * Funzione che restituisce un errore in caso di non riuscita cattura dell'immagine selezionata o scattata (Cordova)
     * 
     * @param {string} message messaggio d'errore
     */
    onFail(message) {
        f7.toast.create({
            text: 'Failed because: ' + message,
            closeTimeout: 2000,
        }).open();
    }

    /**
     * Funzione che aspetta che l'immagine venga caricata
     */
    waitForElement = () => {
        if (document.getElementById("temp_image").src != "") {
            let imagebase64 = document.getElementById("temp_image").src;
            PkCmsDiscover.addModelView(this.props.model.model.uniqueId, imagebase64.substr(22))
                .then((res) => {
                    this.props.f7router.navigate('/discover-view-detail', {
                        props: {
                            model: this.props.model,
                            view: res.data,
                            image: imagebase64,
                            layer: null
                        }, reloadAll: true
                    });
                });
        } else {
            setTimeout(() => {
                this.waitForElement();
            }, 1500);
        }
    }

    /**
     * Funzione che crea una nuova view prendendo un'immagine base64
     * 
     * @param {string} imagebase64 base64 dell'immagine
     */
    createNewView = (imagebase64) => {
        this.resizeImage(imagebase64)
        .then(resizedImageBase64 => {

            PkCmsDiscover.addModelView(this.props.model.model.uniqueId, resizedImageBase64.substr(22))
            .then((res) => {
                this.props.f7router.navigate('/discover-view-detail', {
                    props: {
                        model: this.props.model,
                        view: res.data,
                        image: resizedImageBase64,
                        layer: null
                    }, reloadAll: true
                });
            });
        })

    }

    resizeImage = (base64Str, maxWidth = 640, maxHeight = 640) => {
        return new Promise((resolve) => {
          let img = new Image()
          img.src = base64Str
          img.onload = () => {
            let canvas = document.createElement('canvas')
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            var iw=img.width;
            var ih=img.height;
            var scale=Math.min((MAX_WIDTH/iw),(MAX_HEIGHT/ih));
            var iwScaled=iw*scale;
            var ihScaled=ih*scale;
            canvas.width=iwScaled;
            canvas.height=ihScaled;
            var ctx=canvas.getContext("2d");
            ctx.drawImage(img,0,0,iwScaled,ihScaled);
            resolve(canvas.toDataURL())
          }
        })
      }

    /**
     * Funzione che crea una nuova view con immagine dalla camera
     */
    openCamera() {
        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL
            });

            this.waitForElement();
        } else {
            let picture = this.state.webcam.snap();

            this.createNewView(picture);
        }
    }

    /**
     * Funzione che crea una nuova view con immagine dalla galleria
     * 
     * @param {object} e oggetto che contiene informazioni sull'immagine selezionata
     */
    openGallery(e) {
        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;
            let pictureSource = this.state.pictureSource;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 50,
                destinationType: destinationType.DATA_URL,
                sourceType: pictureSource.PHOTOLIBRARY
            });
            this.waitForElement();
        } else {
            let base64 = "";
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener('load', () => {
                base64 = reader.result;

                if (base64.substr(11, 4) == "jpeg") {
                    base64 = "data:image/png;base64," + base64.substr(23);
                }

                this.createNewView(base64);
            });
        }
    }

    /**
     * Funzione che avvia la webcam del pc
     */
    startWebcam = () => {
        let webcamElement = document.getElementById('webcam');
        let canvas = document.getElementById('temp_canvas');
        let webcam = new Webcam(webcamElement, 'user', canvas);
        this.setState({ webcam: webcam });
        webcam.start()
            .then((result) => {
                console.log("webcam started");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Funzione che permette di modificare una view
     * 
     * @param {object} e oggetto della view da modificare
     */
    modifyView(e) {
        let temp_image = this.state.images.filter(x => { return x.uniqueId === e.uniqueId })[0];
        let temp_layer = this.state.layers.filter(x => { return x.uniqueId === e.uniqueId })[0];
        let image = temp_image ? temp_image.bit_image : "file not found";
        let layer = temp_layer ? temp_layer.bit_layer : "file not found";
        if (temp_image) {
            this.props.f7router.navigate('/discover-view-detail', { props: { model: this.props.model, view: e, image: image, layer: layer }, reloadAll: true })
        }
    }

    /**
     * Funzione che chiama l'api per processare il model
     */
    processModel() {
        document.querySelector("#preloader-container").style.display = "block";
        document.querySelector("#process-model-button").style.display = "none";

        PkCmsDiscover.processModel(this.props.model.model.uniqueId)
            .then((res) => {
                document.querySelector("#preloader-container").style.display = "none";
                document.querySelector("#process-model-button").style.display = "block";

                if (res.status == 4) {
                    f7.toast.create({
                        text: 'Process Failed',
                        closeTimeout: 2000,
                    }).open();
                }
            });
    }

    /**
     * Funzione che chiama l'api che restituisce le immagini delle varie views
     * 
     * @param {object} e oggetto della view
     */
    getImage(e) {
        PkCmsDiscover.getModelViewImage(e.uniqueId)
            .then((res) => {
                let image = { uniqueId: e.uniqueId, bit_image: 'data:image/png;base64,' + res.data };
                this.setState({ images: this.state.images.concat(image) });
            });
    }

    /**
     * Funzione che chiama l'api che restituisce i layer delle varie views
     *
     * @param {object} e oggetto della view
     */
    getLayer(e) {
        PkCmsDiscover.getModelViewLayer(e.uniqueId)
            .then((res) => {
                let layer = { uniqueId: e.uniqueId, bit_layer: 'data:image/png;base64,' + res.data };
                this.setState({ layers: this.state.layers.concat(layer) });
            });
    }

    render() {
        const views = this.state.views.map((e, i) => {
            let image = this.state.images.filter(x => { return x.uniqueId === e.uniqueId })[0];
            let bit_image = image ? image.bit_image : "";

            return (
                <ImageCard key={e.id} item={e} id={e.id} image={bit_image} rating={e.rating} keyPointsCount={e.keyPointsCount} modifyView={() => { this.modifyView(e) }} />
            )
        });
        const contents = this.state.infoContents.map(e => {
            return (
                <ListItem
                    swipeout
                    title={e.content.title}
                    text={e.content.descr}
                    onClick={() => { this.props.f7router.navigate('/discover-content-detail', { props: { infoContent: e.content, model: this.props.model, modifyInfoContent: true }, reloadAll: true }) }}>
                    <img src={e.content.imageUrl != "" ? e.content.imageUrl : placeholder} alt="Image not available" width="80" slot="media" className="lazy lazy-fade-in" />
                    <SwipeoutActions right>
                        <SwipeoutButton delete onClick={() => {
                            PkCmsDiscover.deleteModelContent(e.content.id);
                        }}>
                            Delete
                        </SwipeoutButton>
                    </SwipeoutActions>
                </ListItem>
            )
        })
        return (
            <Page>
                <Navbar>
                    <span className="material-icons icon-arrow-back" onClick={() => { this.props.f7router.navigate('/discover', { reloadAll: true }) }}>arrow_back</span>
                    <PikkartNavbar
                        title={this.props.model.contentData.content.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                    <a className="link popover-open" href="#" data-popover=".popover-links">
                        <span className="material-icons icon-custom-more-vert">more_vert</span>
                    </a>
                    <div className="popover popover-links">
                        <div className="popover-inner">
                            <div className="list">
                                <ul>
                                    <li><a id="process-model-button" className="list-button item-link popover-close" href="#" onClick={() => { this.processModel() }}>Process</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Navbar>
                <div style={{ position: "fixed", bottom: "0", right: "0", zIndex: 100 }}
                    onClick={() => {
                        this.state.tabViews ?
                            <></>
                            :
                            this.props.f7router.navigate('/discover-content-detail', { props: { model: this.props.model, newContent: true }, reloadAll: true })
                    }}>
                    <div className="fab fab-right-bottom">
                        <a href="#">
                            <i className="icon f7-icons if-not-md">plus</i>
                            <i className="icon f7-icons if-not-md">xmark</i>
                            <i className="icon material-icons md-only">add</i>
                            <i className="icon material-icons md-only">close</i>
                        </a>
                        {PkCordova.isCordova() ?
                            <div className="fab-buttons fab-buttons-top">
                                <div onClick={this.openCamera}>
                                    <a className="fab-label-button" href="#">
                                        <span className="material-icons">photo_camera</span>
                                        <span className="fab-label">Open Camera</span>
                                    </a>
                                </div>
                                <div onClick={() => { this.openGallery(null) }}>
                                    <a className="fab-label-button" href="#">
                                        <span className="material-icons">collections</span>
                                        <span className="fab-label">Open Gallery</span>
                                    </a>
                                </div>
                            </div>
                            :
                            <div className="fab-buttons fab-buttons-top">
                                <div onClick={() => { document.getElementById("openCamera").click() }}>
                                    <a id="openCamera" className="button popup-open" href="#" data-popup=".popup-camera" onClick={this.startWebcam} style={{ display: "none" }}></a>
                                    <a className="fab-label-button" href="#">
                                        <span className="material-icons">photo_camera</span>
                                        <span className="fab-label">Open Camera</span>
                                    </a>
                                </div>
                                <div onClick={() => { document.getElementById("openGallery").click() }}>
                                    <input id="openGallery" type="file" onChange={(e) => { this.openGallery(e) }} style={{ display: "none" }} />
                                    <a className="fab-label-button" href="#">
                                        <span className="material-icons">collections</span>
                                        <span className="fab-label">Open Gallery</span>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="toolbar tabbar toolbar-bottom">
                    <div className="toolbar-inner">
                        <a href="#tab-views" className="tab-link tab-link-active" onClick={() => { this.setState({ tabViews: true }) }}>Views</a>
                        <a id="tabContents" href="#tab-contents" className="tab-link" onClick={() => { this.setState({ tabViews: false }) }}>Contents</a>
                    </div>
                </div>
                <Block>
                    <div className="tabs-animated-wrap">
                        <div className="tabs">
                            <div id="tab-views" className="tab tab-active">
                                <div id="preloader-container" style={{ display: "none" }}>
                                    <div className="preloader-container">
                                        <div className="preloader color-red"></div>
                                    </div>
                                </div>
                                <div className="card-container">
                                    {this.state.views.length > 0 ?
                                        views
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="popup popup-camera">
                                    <div className="block">
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <span className="material-icons popup-close" onClick={() => { this.state.webcam.stop() }}>close</span>
                                        </div>
                                        <video id="webcam" autoPlay playsInline style={{ width: "100%" }}></video>
                                        <button className="col button button-raised popup-close" onClick={this.openCamera} style={{ width: "35%" }}>Take picture</button>
                                    </div>
                                </div>
                                <img id="temp_image" style={{ display: "none" }} />
                                <canvas id="temp_canvas" style={{ display: "none" }} />
                            </div>
                            <div id="tab-contents" className="tab">
                                <List mediaList noHairlines noHairlinesBetween>
                                    {contents}
                                </List>
                            </div>
                        </div>
                    </div>
                </Block>
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CREDITS);

        PkCmsDiscover.getModelViews(this.props.model.model.uniqueId)
            .then((res) => {
                if (this.props.deletedView) {
                    let temp_view = res.data.filter(x => { return x.uniqueId != this.props.deletedView });

                    res.data = temp_view;
                }
                this.setState({ views: res.data });
                res.data.forEach((e) => {
                    this.getImage(e);
                    this.getLayer(e);
                });
            }, (error) => {
                console.log(error);
            });

        PkCmsDiscover.getKeypointContentsData(this.props.model.model.contentId)
            .then((res) => {
                if (res.data && res.data.items.length > 0) {
                    this.setState({ infoContents: res.data.items });
                }
            });

        if (PkCordova.isCordova()) {
            this.setState({
                pictureSource: navigator.camera.PictureSourceType,
                destinationType: navigator.camera.DestinationType
            });
        }

        if (this.props.activeContentTab) {
            document.getElementById("tabContents").click();
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverViews);