import React from 'react';
import { connect } from 'react-redux';
import { f7, Block, Page, Navbar, Popup, View, NavRight, NavLeft, Link } from 'framework7-react';
import Commons from '../../commons'
import ContentListItem from '../../contents/content-list-item';
import { PkCmsContents } from 'pikkart-cms';
import appLocaleStrings from '../../app-local';
import TermsPagePopup from '../../components/terms-popup';
import localeStrings from './ar-scenes-local';
import * as actions from '../../redux/actions';
import { RaApiContacts, RaApiAuth, RaStorage, RaLog } from 'pikkart-remote-assistance';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import AnalyticsRegister, { ANALYTICS_SCREEN, ANALYTICS_EVENT } from '../../analytics-register';
import LogOutPopup from '../../components/logout/logout-popup';
import MarkerSwiper from '../../components/marker-swiper';
import { PkCordovaARSdk, PkCordovaPermissions } from 'pikkart-cordova';
import TutorialHelper from '../../helpers/tutorial-helper';

class ArSceneList extends React.Component {
    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            termsPopupOpened: false,
            logoutPopupOpened: false,
            showRecognitionTutorial: false,
            arScenesContentsData: [],
            allowInfinite: true,
            showPreloader: true,
            errorMessage: null,
            company: null,
            companyCode: null,
            selectedCompany: null,
            customers: [],
        };
    }

    render() {
        const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        const showLogoutButton = (menuItem && menuItem.code === '/arscenes') && RaApiAuth.isAuthenticated();

        //const showChangeCustomerButton = userCustomers ? userCustomers.length > 1 : false;

        const markerSwiper = (
            this.state.arScenesContentsData.length > 0 && this.props.appCompany ?
                <MarkerSwiper
                    onMarkersClick={this.onMarkersClickHandler}
                    clientId={this.props.appCompany.Id}
                    markerSwiperswiperTitle={localeStrings.marker_swiper_title} />
                : null
        )

        const getRecognitionTutorialPopup = () => {
            return (
                <Popup tabletFullscreen={true} push opened={this.state.showRecognitionTutorial} closeOnEscape={true}>
                    <View>
                        <Page>
                            <Navbar transparent={true}>
                                <NavLeft>
                                    <Link onClick={() => { this.setState({ showRecognitionTutorial: !this.state.showRecognitionTutorial }) }} popupClose iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                                </NavLeft>
                                <NavRight>
                                    <img slot="media" src={'./img/logo/logo.png'}
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
                                </NavRight>
                            </Navbar>

                            {TutorialHelper.getArSceneListTutorial(this.toggleClicked, () => this.startMarkerRecognition(this.props.appCompany.Id))}
                        </Page>
                    </View>
                </Popup>
            )
        }

        return (
            <Page
                infinite
                infinitePreloader={this.state.showPreloader}
                onInfinite={() => this.fetchArScenesContents(this.state.companyCode, false)}
            >
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false}
                        defaultCustomer={this.state.company}
                        userCustomers={this.state.customers}
                        showChangeCustomerButton={this.state.customers.length > 1}
                        changeCustomerButtonClicked={this.navBarChangeCustomerClickHandler}
                        showLogoutButton={showLogoutButton}
                        logoutButtonClicked={this.navBarLogoutClickHandler} />
                </Navbar>
                <div>
                    {this.getArScenesContentsData(markerSwiper)}
                </div>

                <TermsPagePopup
                    navBarTitle={menuItem.title}
                    opened={this.state.termsPopupOpened}
                    confirmButtonClicked={this.termsPopupOpenedConfirmClickHandler}
                    dismissButtonClicked={this.termsPopupOpenedDismissiClickHandler}
                    title={localeStrings.terms_title}
                    description={localeStrings.terms_description}
                    confirmButtonText={localeStrings.confirm_button_text}
                    dismissiButtonText={localeStrings.dismiss_button_text}
                    logo='./img/logo/logo.png'
                />

                <LogOutPopup
                    closeIconClick={this.closeIconClickHandler}
                    logoutDialogConfirmed={this.logoutDialogConfirmClickHandler}
                    context={this}
                    navBarTitle={localeStrings.logout_screen_title}
                    opened={this.state.logoutPopupOpened}
                    logo='./img/logo/logo.png'
                />

                {getRecognitionTutorialPopup()}
            </Page>
        )
    }

    componentDidMount = () => {

        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.AR_SCENE_LIST);

        const smartSelects = document.getElementsByClassName('smart-select');
        if (smartSelects) {
            while (smartSelects.length > 0) {
                smartSelects[0].parentNode.removeChild(smartSelects[0]);
            }
        }

        const acceptedTermsOfUse = JSON.parse(localStorage.getItem('accepted_terms_of_use'));

        if (navigator.onLine) {
            if (acceptedTermsOfUse) {
                this.props.setAcceptedTermsOfUse(acceptedTermsOfUse);

                this.setState({ termsPopupOpened: false });

                let company = null;
                let companyCode = null;
                const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
                if (menuItem && menuItem.code === '/arscenes') {
                    Commons.checkAuthentication(this, this.props.f7router, menuItem.code);
                    if (!RaApiAuth.isAuthenticated()) {
                        //return;
                    }

                    company = RaStorage.getLoginCompany();
                    if (company) companyCode = company.Code;
                }
                // else if (menuItem === null)
                //     return;

                if (company) {
                    this.setState({ company: company, selectedCompany: company, companyCode: companyCode });
                    this.fetchCompanyList();
                    this.fetchArScenesContents(companyCode, false);
                }
            }
            else {
                const self = this;
                setTimeout(() => {
                    self.setState({ termsPopupOpened: true });
                }, 100);
            }
        }
        else {
            this.setState({
                errorMessage: appLocaleStrings.no_contents,
                showPreloader: false
                // infoContentsData: []
            });
            this.showToastBottom(appLocaleStrings.no_connection_message);
        }
    }

    showToastBottom = (text) => {
        this.toastBottom = f7.toast.create({
            text: text,
            closeTimeout: 2000,
        });
        this.toastBottom.open();
    };

    fetchCompanyList = () => {
        const self = this;
        self.startGetCompanies();
    }

    startGetCompanies = () => {
        const self = this;
        console.log('get companies');
        this.getCompanies().then(function (customers) {
            if (customers.length >= 1) {
                self.setState({ customers: customers });
            }
            else {
                self.setState({ customers: [] });
            }
        })
            .catch(function (error) {
                if (error.errorCode === 401) {
                    //invalid token
                    const locRouter = self.props.f7router;
                    const menuItem = Commons.getMenuItemFromRoute(self.props.f7route, self.props.menu);
                    //rimando al login
                    setTimeout(function () {
                        localStorage.setItem('menu', JSON.stringify(self.props.menu));
                        localStorage.setItem('accepted_terms_of_use', !self.state.termsPopupOpened);
                        localStorage.setItem('skip_ar_logo_tutorial', self.props.skippedArLogoTutorial);
                        localStorage.setItem('skip_ar_scene_tutorial', self.props.skippedArSceneTutorial);

                        if (locRouter.app) {
                            locRouter.app.views.main.router.navigate('/login/', { reloadCurrent: true, ignoreCache: true, props: { redirectPage: menuItem.code } });
                        }
                    }, 500);
                }
                self.setState({
                    errorMessage: error,
                    showPreloader: false
                });
            });
    }

    getCompanies = () => {
        return new Promise((resolve, reject) => {
            const errorFn = (error) => {
                reject(error);
            }

            const successFn = (customers) => {
                resolve(customers);
            }

            RaApiContacts.getCompanies()
                .then(function (response) {

                    if (response.result.success === true) {
                        // handle success
                        successFn(response.data);

                    } else {
                        // handle error
                        errorFn({ errorCode: response.result.code, errorMsg: response.result.message });
                    }
                })
                .catch(function (errorMessage) {
                    //handle error
                    errorFn({ errorMsg: errorMessage });
                });

        });
    };

    fetchArScenesContents = (owCode, resetPages) => {
        RaLog.log('fetchArScenesContents ', owCode);
        if (!this.state.allowInfinite) return;
        this.setState({ allowInfinite: false });
        if (resetPages) {
            this.page = 0;
        }
        this.page += 1;

        const params = owCode ?
            { c1: this.props.c1, c2: this.props.c2, c3: this.props.c3, page: this.page, pageSize: this.pageSize, owCode: owCode } :
            { c1: this.props.c1, c2: this.props.c2, c3: this.props.c3, page: this.page, pageSize: this.pageSize }

        PkCmsContents.getArScenesContentsDataPage(params)
            .then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0) {
                        if (this.state.arScenesContentsData.length > 0 && !resetPages) {
                            this.setState({ showPreloader: false });
                            return;
                        }
                        errorFn(appLocaleStrings.no_contents);
                    }
                    else {
                        successFn(response.data.items);
                    }
                }
                else {
                    errorFn(response.result.message);
                }
            })
            .catch((errorMessage) => {
                errorFn(errorMessage);
            });

        const errorFn = (errorMsg) => {
            if (this.page !== 1) {
                this.setState({ showPreloader: false });
                return;
            }
            this.setState({
                errorMessage: errorMsg,
                showPreloader: false,
                allowInfinite: true,
            });
            if (resetPages) {
                this.setState({
                    arScenesContentsData: [],
                });
            }
        }

        const successFn = (arScenesContentsData) => {
            this.setState({ arScenesContentsData: resetPages ? arScenesContentsData : this.state.arScenesContentsData.concat(arScenesContentsData) });

            this.setState({ errorMessage: null, showPreloader: false });

            if (arScenesContentsData.length === this.pageSize)
                this.setState({
                    allowInfinite: true,
                });
        }
    }

    getArScenesContentsData = (elementToShowUnderTheList) => {

        if (this.state.arScenesContentsData === null ||
            this.state.arScenesContentsData.length === 0) {
            if (this.state.errorMessage) {
                return (
                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const ret = this.state.arScenesContentsData.map((item, index) => {
            return (<ContentListItem
                currentCompany={this.state.selectedCompany}
                contentData={item}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onCardClick(item);
                }}></ContentListItem>);
        });


        return (
            <Block style={{ padding: '0' }}>
                <div className="demo-expandable-cards">{ret}</div>

                {elementToShowUnderTheList}
                {/*<PageContent style={{ padding: '0' }}>{ret}</PageContent>*/}
            </Block>)
    }

    onCardClick = (item) => {
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_AR_SCENE_DETAIL, item)
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }

    redirectToMainPage = () => {
        const self = this;
        this.setState({ termsPopupOpened: false }, () => {

            let menuItems = self.props.menu ? self.props.menu : [];
            let path = '/content-list/';
            menuItems.forEach(item => {
                if (item.code === process.env.REACT_APP_DEFAULT_CODE_PAGE)
                    path = Commons.getMenuItemUrl(item)
            });

            self.props.f7router.navigate(path, { reloadAll: true });
        })
    }

    termsPopupOpenedConfirmClickHandler = () => {

        localStorage.setItem('accepted_terms_of_use', true);
        this.props.setAcceptedTermsOfUse(true);

        const self = this;
        this.setState({ termsPopupOpened: false }, () => {

            let companyCode = null;
            const menuItem = Commons.getMenuItemFromRoute(self.props.f7route, self.props.menu);
            if (menuItem && menuItem.code === '/arscenes') {
                Commons.checkAuthentication(self, self.props.f7router, menuItem.code);
                if (!RaApiAuth.isAuthenticated()) {
                    return;
                }

                companyCode = RaStorage.getLoginCompany().Code;
            }
            else if (menuItem === null)
                return;


            self.setState({ companyCode: companyCode });
            RaLog.log('Terms popup confirmed');
            self.fetchArScenesContents(companyCode, false);
        })
    }

    termsPopupOpenedDismissiClickHandler = () => {
        this.redirectToMainPage();
    }

    navBarLogoutClickHandler = () => {
        const self = this;
        setTimeout(() => {
            self.setState({ logoutPopupOpened: true });
        }, 100);
    }

    navBarChangeCustomerClickHandler = () => {
        const locf7 = f7;
        const selectedCustomerCode = locf7.smartSelect.get('.smart-select').$selectEl.val();
        let selectedCustomer;
        this.state.customers.forEach(customer => {
            if (customer.Code === selectedCustomerCode)
                selectedCustomer = customer
        });
        this.setState({
            allowInfinite: true,
            showPreloader: true,
            selectedCompany: selectedCustomer,
        }, () => {
            this.fetchArScenesContents(selectedCustomerCode, true);
        });
    }

    logoutDialogConfirmClickHandler = () => {

        localStorage.setItem('menu', JSON.stringify(this.props.menu));
        localStorage.setItem('accepted_terms_of_use', this.props.acceptedTermsOfUse);

        const self = this;
        this.setState({ logoutPopupOpened: false }, () => {
            //rimando al login
            setTimeout(function () {
                const menuItem = Commons.getMenuItemFromRoute(self.props.f7route, self.props.menu);
                if (menuItem && menuItem.code === '/arscenes') {
                    Commons.checkAuthentication(self, self.props.f7router, menuItem.code);
                }
            }, 200);
        });
    }

    closeIconClickHandler = () => {
        this.setState({ logoutPopupOpened: false });
    }

    onMarkersClickHandler = () => {
        const skippedArLogoTutorial = JSON.parse(localStorage.getItem('skip_ar_logo_tutorial'));
        if (skippedArLogoTutorial) {
            this.props.setSkippedArLogoTutorial(skippedArLogoTutorial);
            this.startMarkerRecognition(this.props.appCompany.Id, null/*this.startMarkerRecognitionCallback*/);
        }
        else {
            this.setState({ showRecognitionTutorial: true })
        }
    }

    toggleClicked = (selected) => {
        this.props.setSkippedArLogoTutorial(selected);
        localStorage.setItem('skip_ar_logo_tutorial', selected);
        console.log("toggleClicked setItem " + selected);
    }

    startMarkerRecognition = (deviceId, callback) => {
        const self = this;
        let _errorDialog = null;
        let _progressDialog = null;

        let apiUrl = process.env.REACT_APP_CMS_API_URL;

        const startRecognitionFragment = () => {//apiUrl, cmsContentPageUrl, enableGlobalRecognition
            self.props.setActivityPaused(true);
            PkCordovaARSdk.startRecognition(apiUrl, "?contentid=", false)
                .then(() => {
                    console.log('recognition success')
                })
                .catch(() => {
                    console.log('recognition error')

                    openErrorDialog('start recognition error');
                });
        }

        const downloadMarkers = () => {
            PkCordovaPermissions.askStoragePermission().then((status) => {
                openDeterminedProgress();
                PkCordovaARSdk.downloadMarkers(apiUrl, deviceId, finished, downloadMarkerError, progress);
            }).catch((err) => {
                console.log(err);
            });

            const finished = () => {
                console.log('download markers finished');
                _progressDialog.setText(localeStrings.loading_markers_complete);

                closeProgressDialog(true);
                startRecognitionFragment();
            };

            const progress = (progressValue) => {
                console.log('markers progress ' + progressValue);
                if (_progressDialog)
                    _progressDialog.setProgress(progressValue);
                Math.round(progressValue)
                _progressDialog.setText(localeStrings.formatString(localeStrings.loading_markers_percentage, Math.round(progressValue)));
            };

            const downloadMarkerError = () => {
                if (self.state.downloadMarkerFirstAttempt) {
                    closeProgressDialog(false, () => {
                        self.setState({ downloadMarkerFirstAttempt: false }, () => {
                            downloadMarkers();
                        })
                    });
                }
                else {
                    _progressDialog.setText(localeStrings.loading_markers_error);
                    closeProgressDialog(true);
                }
            };

            const openDeterminedProgress = () => {
                const app = f7;
                let progress = 0;
                _progressDialog = app.dialog.progress(localeStrings.download_title, progress);
                _progressDialog.setText(localeStrings.download_text);
            }
        }

        const openErrorDialog = (error) => {
            console.log(error);
            const app = f7;
            _errorDialog = app.dialog.alert(localeStrings.ar_error_text, () => {
                if (callback) {
                    callback();
                }
            });
            _errorDialog.setTitle(localeStrings.ar_error_title);

        }

        const closeProgressDialog = (redirect, closeCallback) => {
            setTimeout(function () {
                _progressDialog.close();
                if (closeCallback)
                    closeCallback();

                if (callback && redirect) {
                    callback();
                }

            }, this.state.dialogClosingTime)
        }

        downloadMarkers();
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        acceptedTermsOfUse: state.app.acceptedTermsOfUse,
        appCompany: state.remoteAssistance.appCompany,
        profile: state.remoteAssistance.profile,
        skippedArLogoTutorial: state.app.skippedArLogoTutorial,
        skippedArSceneTutorial: state.app.skippedArSceneTutorial,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAcceptedTermsOfUse: (acceptedTermsOfUse) => dispatch(actions.setAcceptedTermsOfUse(acceptedTermsOfUse)),
        setSkippedArLogoTutorial: (skippedArLogoTutorial) => dispatch(actions.setSkippedArLogoTutorial(skippedArLogoTutorial)),
        setActivityPaused: (activityPaused) => dispatch(actions.setActivityPaused(activityPaused)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ArSceneList);