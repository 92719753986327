import React from 'react';
import { connect } from 'react-redux';
import { Navbar, Page, Block, List, ListInput, Button } from 'framework7-react';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import Commons from '../../commons';
import localeStrings from './contact-us-local'

class ContactUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false} />
                </Navbar>
                <Block>
                    <List form formStoreData id="demo-form">
                        <ListInput
                            floatingLabel={true}
                            label={localeStrings.name}
                            type="text"
                            clearButton
                            placeholder={localeStrings.name_placeholder} />
                        <ListInput
                            floatingLabel={true}
                            label={localeStrings.email}
                            type="email"
                            clearButton
                            placeholder={localeStrings.email_placeholder} />
                        <ListInput
                            floatingLabel={true}
                            label={localeStrings.subject}
                            type="text"
                            clearButton
                            placeholder={localeStrings.subject_placeholder} />
                        <ListInput
                            floatingLabel={true}
                            label={localeStrings.message}
                            type="textarea"
                            resizable
                            placeholder={localeStrings.message_placeholder} />
                    </List>
                    <Block>
                        <Button
                            style={{ marginTop: '70px', width: 'fit-content', padding: 'inherit' }}
                            large
                            fill>{localeStrings.submit_button_text}</Button>
                    </Block>
                </Block>
            </Page>
        )
    }

    componentDidMount = () => {

    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);